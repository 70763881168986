import { UPDATE_DEVICE_INFO } from '../actionTypes';
import { ResourceManager } from 'Services';
import { sendTrackingData } from 'Utils';
import { getBackend, setConfigOptions } from '../uiStatus';

export const getGPUDetectedStatus = (state) => state.deviceInfoReducer?.deviceInfo?.gpu?.GPUDetected;

export const getWebGPU = (state) => state.deviceInfoReducer?.deviceInfo?.webgpu;

export const getDeviceRenderer = (state) => state.deviceInfoReducer?.deviceInfo?.gpu?.renderer;

export const deviceInfo = () => async (dispatch, state) => {
  const deviceInfo = ResourceManager.deviceInfo();
  sendTrackingData([{ name: 'GPU Information', data: { gpu: deviceInfo.gpu.renderer } }]);
  dispatch({
    type: UPDATE_DEVICE_INFO,
    payload: deviceInfo,
  });

  if (!getWebGPU(state()) && getBackend(state()) === 'webgpu') {
    dispatch(setConfigOptions({ backend: 'webgl' }));
  }
};
