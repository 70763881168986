import { UPDATE_INFERENCE_STATUS } from '../../actions/actionTypes';
import GlobalConfig from 'Config';

const resolveExistingRunner = (state, data) => {
  let prev = state.inferenceStatus;

  const { active, message, major, ...otherData } = data;

  const newData = {
    ...prev,
    ...otherData,
    active: (active !== null && active !== undefined) ? active : prev.active,
  };

  if (message) newData.steps.push({ message, major });

  return newData;
};

export const inferenceReducer = (state = GlobalConfig.initialState.inference, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_INFERENCE_STATUS:
      return {
        inferenceStatus: resolveExistingRunner(state, payload),
      };
    default:
      return state;
  }
};
