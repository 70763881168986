import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, RemoveFilesModal } from 'Components';
import classNames from 'classnames';
import { formatBytes, i18n } from 'Utils';
import { buttonArrow, remove } from 'Images';

import './ProcessList.scss';

const ProcessList = ({ files, setDroppedFiles }) => {
  const [showAll, toggleShowAll] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const closeModal = () => {
    toggleModal(false);
    setItemToRemove(null);
  };

  const handleItemToRemove = (item) => {
    setItemToRemove(item);
    toggleModal(true);
  };

  const removeAll = () => setDroppedFiles([]);

  const removeFile = (idx) => setDroppedFiles((s) => s.filter((item) => s.indexOf(item) !== idx));

  const listClasses = classNames({
    'process-list': true,
    'process-list__show-all': showAll,
    'ps-0': true,
  });

  return (
    <Container className={listClasses}>
      {showModal && (
        <RemoveFilesModal
          closeModal={closeModal}
          itemToRemove={itemToRemove}
          removeAction={itemToRemove !== null ? removeFile : removeAll}
        />
      )}
      <Row className="process-list__header font-700">
        <Col md="2">
          <span>{i18n('File')} </span>
        </Col>
        <Col md="2">
          <span>{i18n('Size')} </span>
        </Col>
        <Col md="2">
          <span>{i18n('Date Sequenced')} </span>
        </Col>
        <Col md="2">
          <span>{i18n('Count')} </span>
        </Col>
        <Col md="2">
          <span onClick={() => handleItemToRemove(null)}>
            <span className="process-list__remove_all-text ">{i18n('Remove all')}</span>
          </span>
        </Col>
        <Col md="2">
          <span className="see-all" onClick={() => toggleShowAll((s) => !s)}>
            <img src={buttonArrow} className="me-2 see-all-icon" /> {i18n('See all')}
          </span>
        </Col>
      </Row>

      {files.map(({ name, size, sequenceDate, count }, idx) => (
        <Row className="process-list__item" key={`${name}-${idx}`}>
          <Col md="2" className="process-list__filename">
            <span>{name}</span>
          </Col>
          <Col md="2">
            <span>{formatBytes(size)}</span>
          </Col>
          <Col md="2">
            <span>{sequenceDate.slice(0, 10)}</span>
          </Col>
          <Col md="2">
            <span>{count}</span>
          </Col>
          <Col md="4">
            <span onClick={() => handleItemToRemove(idx)}>
              <img className="process-list__remove_single-icon" src={remove} alt={i18n('Remove single file')} />
            </span>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

ProcessList.propTypes = {
  files: PropTypes.array,
  setDroppedFiles: PropTypes.func,
};

export default ProcessList;
