export const fast5Extension = '.fast5';
export const fastqExtension = '.fastq';
export const samExtension = '.sam';
export const bamExtension = '.bam';
export const summaryExtension = '.csv';
export const sequenceSuffix = '_sequence';
export const rawSuffix = '_raw';
export const readGroupsKey = 'read_groups';
export const readTagsKey = 'read_tags';
export const metaDataSuffix = '_meta_data';
export const dataSuffix = '_data';
