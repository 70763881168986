import React from 'react';

const LogBox = (props) => {
  return (
    <div className="logbox my-4">
      <div className="logbox__body">{props.children}</div>
      <hr align="center" className="mt-6" />
    </div>
  );
};

export default LogBox;
