import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { i18n } from 'Utils';
import { setConfigOptions, getLibrary, getBackend, clearOfflineModels } from 'Actions';
import { config } from 'Images';
import { Dropdown } from 'Components';

import './Configuration.scss';

const Configuration = ({ backend, library, setConfigOptions }) => {
  const [modalIsOpen, toggleModal] = useState(false);
  const [optionsAreShown, toggleOptions] = useState(false);
  const [backendState, setBackend] = useState(backend);
  const [libraryState, setLibrary] = useState(library);
  const [modalPosition, setModalPosition] = useState({});

  const closeModal = () => {
    toggleOptions(false);
    toggleModal(false);
  };

  const saveConfig = () => {
    setConfigOptions({ backend: backendState, library: libraryState  });
    closeModal();
  };

  const clearModels = () => {
    clearOfflineModels();
    closeModal();
  };

  let buttonRef = useRef(null);

  const openModal = () => {
    if (buttonRef.current) {
      const { x, width } = buttonRef.current.getBoundingClientRect();
      const modalWidth = 366;
      const posistionLeft = width + x - modalWidth;
      setModalPosition({ left: `${posistionLeft}px`, width: `${modalWidth}px` });
    }
    toggleModal(true);
  };

  useEffect(() => {
    setBackend(backend);
  }, [backend]);

  return (
    <div className="config-wrapper">
      <button className="config-button" onClick={openModal} ref={buttonRef}>
        {i18n('Configuration')}
        <img src={config} className="ms-2" />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-container config-modal"
        overlayClassName="modal-overlay"
        contentLabel="Set config options"
        appElement={document.querySelector('#root')}
        style={{
          content: {
            ...modalPosition,
          },
        }}
      >
        <i className="arrow-top"></i>
        <p className="mb-2">
          {i18n(
            "Below you can change the machine learning library and hardware processor that get used when basecalling. Only use these settings if you know what you're doing.",
          )}
        </p>

        {optionsAreShown && (
          <div className="dropdown-section">
            <Dropdown
              label="ML Library"
              selectedValue={libraryState}
              onChange={(e) => setLibrary(e.target.value)}
              options={['Tensorflow']}
            />

            <Dropdown
              label="Processor"
              selectedValue={backendState}
              onChange={(e) => setBackend(e.target.value)}
              options={['CPU', 'WASM', 'WebGL', 'WebGPU']}
            />

          </div>
        )}

        <div className={`button-wrapper ${optionsAreShown ? 'options-open' : ''}`}>
          {!optionsAreShown ? (
            <>
              <button className="options-button" onClick={() => toggleOptions(true)}>
                {i18n('Show options')}
              </button>
              <button className="close-button" onClick={closeModal}>
                {i18n('Close')}
              </button>
            </>
          ) : (
            <>
              <button className="options-button" onClick={clearModels}>
                {i18n('Clear offline models')}
              </button>
              <button className="save-button" onClick={saveConfig}>
                {i18n('Save')}
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    backend: getBackend(state),
    library: getLibrary(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfigOptions: (options) => dispatch(setConfigOptions(options)),
    clearOfflineModels: () => dispatch(clearOfflineModels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);

Configuration.propTypes = {
  backend: PropTypes.string,
  library: PropTypes.string,
  setConfigOptions: PropTypes.func,
};
