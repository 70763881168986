import { GPU } from 'gpu.js';
import * as hdf5 from 'jsfive/dist/esm';
import * as math from 'mathjs';
import Profiler from '../../profiler';
import { decompress } from 'vbzjs';


const VBZ_FILTER = 32020;
hdf5.Filters.set(VBZ_FILTER, function (buf, itemSize, clientData) {
  return decompressWithOptions(buf, itemSize, clientData);
});

const decompressWithOptions = (buffer, itemSize, clientData) => {
  // client data is an array, example: [0, 2, 1, 1, 1];
  const options = {
    perform_delta_zig_zag: clientData[2] === 1,
    integer_size: itemSize,
    zstd_compression_level: clientData[3],
    vbz_version: clientData[0],
    zstd: clientData[4],
  };
  return decompress(buffer, options, true);
};


export const normaliseSignal = (signal) => {
  const [q20, q90] = math.quantileSeq(signal, [0.2, 0.9]);
  const shift = math.max(10, 0.51 * (q20 + q90));
  const scale = math.max(1.0, 0.53 * (q90 - q20));

  return { shift, scale }
}


export default class MultiFast5 {
  constructor(buffer, filename, profilerOffset, profilerId) {
    this.profilerOffset = profilerOffset;
    this.profilerId = profilerId;
    this.filename = filename;
    this.file = new hdf5.File(buffer, filename);
    this.gpu = new GPU();
    this.kernel = {
      signal: this.gpu.createKernel(
        function (dac, shift, scale) {
          return (dac[this.thread.x] + shift) * scale;
        },
        {
          dynamicArguments: true,
          dynamicOutput: true,
        },
      ),
    }
  }

  destroy() {
    this.gpu.destroy();
    Object.keys(this.kernel).forEach(k => this.kernel[k].destroy());
  }

  length() {
    return this.file.keys.length;
  }

  read_ids() {
    return this.file.keys;
  }

  *reads() {
    for (let key of this.file.keys) {
      yield new Read(this.file.get(key), this.kernel, this.profilerOffset, this.profilerId);
    }
  }
}

export class Read {
  constructor(group, kernel, profilerOffset, profilerId) {
    this.profilerOffset = profilerOffset;
    this.profilerId = profilerId;
    this.group = group;
    this.norm_signal_calculated = null;
    this.signal_gpu_calculated = null;
    this.signal_calculated = null;
    this.scale_calculated = null;
    this.kernel = kernel;
  }

  get profiler() {
    return Profiler;
  }

  get channel_id() {
    return this.group.get('channel_id').attrs;
  }

  get context_tags() {
    return this.group.get('context_tags').attrs;
  }

  get raw() {
    this.profileEvent('startEvent', 'raw', 'Raw Read');
    const val = this.group.get('Raw').attrs;
    this.profileEvent('endEvent', 'raw', 'Raw Read');
    return val;
  }

  get dac() {
    return this.group.get('Raw').get('Signal').value;
  }

  get shift() {
    return this.channel_id.offset;
  }

  get scale() {
    if (this.scale_calculated !== null) {
      return this.scale_calculated;
    }
    this.scale_calculated = this.channel_id.range / this.channel_id.digitisation;
    return this.scale_calculated;
  }

  get norm_signal() {
    if (this.norm_signal_calculated !== null) {
      return this.norm_signal_calculated;
    }

    this.profileEvent('startEvent', 'norm_signal', 'Norm Signal');

    const signal_arr = [...this.signal_gpu];

    this.profileEvent('startEvent', 'normaliseSignal', 'Qunaltile Signal');
    
    const { shift, scale } = normaliseSignal(signal_arr);
    const signal = signal_arr.map((s) => (s - shift) / scale);

    this.norm_signal_calculated = { signal, shift, scale };

    this.profileEvent('endEvent', 'normaliseSignal', 'Qunaltile Signal');

    this.profileEvent('endEvent', 'norm_signal', 'Norm Signal');

    return this.norm_signal_calculated;
  }

  profileEvent(type = 'startEvent', id, name) {
    Profiler[type]({ id, name, offset: this.profilerOffset, tid: this.profilerId });
  }

  get signal_gpu() {
    if (this.signal_gpu_calculated !== null) {
      return this.signal_gpu_calculated;
    }
    this.profileEvent('startEvent', 'signal_gpu', 'Signal GPU');
    // Raw signal, Offset, Scale = range/digitisation
    this.kernel.signal.setOutput([this.dac.length]);
    const exec = this.kernel.signal(this.dac, this.shift, this.scale);
    this.signal_gpu_calculated = exec;

    this.profileEvent('endEvent', 'signal_gpu', 'Signal GPU');
    return this.signal_gpu_calculated;
  }

  get tracking_id() {
    return this.group.get('tracking_id').attrs;
  }

  get exp_start_time() {
    return this.tracking_id.exp_start_time;
  }

  get run_id() {
    return this.tracking_id.run_id;
  }
}
