/* eslint-disable*/
import hrtime from 'browser-process-hrtime';
import { logger } from 'Utils/logger';
process.hrtime = hrtime;

class Profiler {
  constructor() {
    this.profileName = new Date().toLocaleString().replace(/\//g, '-').replace(', ', 'T') + '.json';
    this.displayTimeUnit = 'ns';
    this.totalTime = {
      microSeconds: 0,
    };
    this.initialOffset = [];
  }

  traceEvents = [];

  calculateOffset(offset) {
    let calculatedOffset = 0;
    if (offset) {
      calculatedOffset = this.initialOffset[0];
    }
    return calculatedOffset;
  }

  startEvent({ id, name, offset = 0, category, tid = null, log = false, pid }) {
    if (this.initialOffset.length === 0) {
      this.initialOffset.push(offset - this.getHRTS());
    }

    const calculatedOffset = this.calculateOffset(offset);
    const event = new Event(id, name, category, 'B', tid, calculatedOffset, pid);
    this.traceEvents.push(event);
    if (log) logger.log(name, id);
  }

  endEvent({ id, name, offset = 0, category, tid = null, log = false, pid }) {
    const calculatedOffset = this.calculateOffset(offset);
    const event = new Event(id, name, category, 'E', tid, calculatedOffset, pid);
    this.traceEvents.push(event);
    if (log) logger.log(name, id);
  }

  asyncStartEvent(id, name, category, tid) {
    const event = new Event(id, name, category, 'b', tid);
    this.traceEvents.push(event);
  }

  asyncEndEvent(id, name, category, tid) {
    const event = new Event(id, name, category, 'e', tid);
    this.traceEvents.push(event);
  }

  getTotalTime() {
    this.totalTime.microSeconds = this.traceEvents[this.traceEvents.length - 1].ts - this.traceEvents[0].ts;
    return this.totalTime.microSeconds;
  }

  clear() {
    this.traceEvents.length = 0;
  }

  parse() {
    this.getTotalTime();
    return this;
  }

  getHRTS() {
    return getHRTS();
  }
}

const instance = new Profiler();
Object.freeze(instance);
export default instance;

class Event {
  constructor(id, name, category = 'general', ph, tid = '1234', offset = 0, pid = '1234') {
    this.name = name;
    this.id = id;
    this.pid = pid;
    this.tid = tid;
    this.cat = category;
    this.ph = ph;
    this.dateTime = this.getDateTime();
    const eventTS = this.getEventTs(offset);
    this.ts = eventTS;
    this.tts = eventTS;
  }

  getDateTime() {
    return new Date().toISOString();
  }

  getEventTs(offset) {
    return getHRTS(offset);
  }
}

const getHRTS = (offset = 0) => {
  const hrtime = process.hrtime(); // [seconds, nanoseconds]
  return hrtime[0] * 1000000 + Math.round(hrtime[1] / 1000) + offset; // microseconds
};
