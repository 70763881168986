import React from 'react';
import { Container, Row, AboutWebGPU, GPUTimeline, EnableWebGPU } from 'Components';

const WebGPU = () => {
  return (
    <div className="webgpupage">
      <Container>
        <Row>
          <AboutWebGPU />
        </Row>
      </Container>
      <Container fluid className="px-0 overflow-hidden">
        <Row>
          <GPUTimeline />
        </Row>
      </Container>
      <Container>
        <Row>
          <EnableWebGPU />
        </Row>
      </Container>
    </div>
  );
};

export default WebGPU;
