import React from 'react';
import './WPCard.scss';
import { i18n } from 'Utils';

const WPCard = ({ image, title, description }) => {
  return (
    <div className="wp-card my-6">
      <img src={image} alt="icon" />
      <div className="wp-card__body">
        <h4>{i18n(title)}</h4>
        <p className="wp-card__description mb-0">{i18n(description)}</p>
      </div>
    </div>
  );
};

export default WPCard;
