import React from 'react';
import './BrowserCard.scss';
import { i18n } from 'Utils';

const BrowserCard = ({ image, title, pointOne, pointTwo, pointThree, pointFour, classname = '' }) => {
  return (
    <div className={`browsercards ${classname}`}>
      <div className="browsercards__body">
        <div className="browsercards__header d-flex mb-3">
          {image && <img src={image} className="browsercards__image me-2" />}
          <h5 className="browsercards__title">{i18n(title)}</h5>
        </div>
        <div className="browsercards__description">
          <p className="browsercards__text mb-3" dangerouslySetInnerHTML={{ __html: pointOne }} />
          <p className="browsercards__text mb-3" dangerouslySetInnerHTML={{ __html: pointTwo }} />
          <p className="browsercards__text mb-3" dangerouslySetInnerHTML={{ __html: pointThree }} />
          <p className="browsercards__text mb-0" dangerouslySetInnerHTML={{ __html: pointFour }} />
        </div>
      </div>
    </div>
  );
};

export default BrowserCard;
