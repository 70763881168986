import PropTypes from 'prop-types';
import React from 'react';

const ReferenceFile = ({ onChange }) => {
  return <input id="referenceFile" type="file" accept=".fastq,.fq,.fasta,.fa,.mmi" onChange={onChange}></input>;
};

ReferenceFile.propTypes = {
  onChange: PropTypes.func,
};

export default ReferenceFile;
