import fileDownload from 'js-file-download';
import SummaryFile from '../summary';

export default class OutputHandler {
  constructor(referenceFile) {
    this.files = [];
    this.profileData = null;
    this.referenceFile = referenceFile;
    this.summaryFile = new SummaryFile();
  }

  async addFile(file) {
    this.files.push(file);
  }

  async downloadFiles(downloadProfiling = false) {
    for (const file of this.files) {
      await file.downloadFile(this.referenceFile, this.summaryFile);
    }

    await this.summaryFile.downloadFile();

    if (downloadProfiling) {
      await fileDownload(JSON.stringify(this.profileData), 'profile.json'); // Comment this out to not download profile file. TODO: Add a way to toggle profiling.
    }
  }

  async appendProfileData(profileData) {
    this.profileData = profileData;
  }

  async clearEntries() {
    this.profileData = null;
    for (const file of this.files) {
      await file.clearDB();
    }
    this.files.length = 0;
  }
}
