import './Dashboard.scss';

import {
  AnyQuestions,
  AppText,
  BatchProgress,
  Col,
  Container,
  DevTools,
  Dropzone,
  GPUDetection,
  HowItWorks,
  NotSupportedModal,
  ProcessDetails,
  Row,
  UnloadHandler,
  WorkPrivate,
} from 'Components';
import React, { useEffect, useState } from 'react';

import GlobalConfig from 'Config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAppStatus } from 'Actions';
import { isSafari } from 'react-device-detect';
import { logger } from 'Utils/logger';

const Dashboard = ({ appStatus }) => {
  const { inferenceStarted, model } = appStatus;
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [referenceFile, setReferenceFile] = useState();
  const [batchConfig, setBatchConfig] = useState(false);
  const filesDropped = (files) => setDroppedFiles(files);
  const droppedFilesLoaded = droppedFiles.length > 0;
  const isModelSet = model ? Object.keys(model).length > 0 : false;

  const prepareML = async () => {
    const mlconfig = {
      ...GlobalConfig.mlconfig,
      modelStride: model?.stride,
      features: model?.features,
    };

    logger.dump('mlconfig', mlconfig);
    setBatchConfig(mlconfig);
  };

  useEffect(() => {
    prepareML();
  }, [model]);

  return (
    <div className="dashboard">
      {isSafari && (
        <div className="dashboard">
        <Container>
          <Row>
            <NotSupportedModal />
          </Row>
        </Container>
      </div>
      )}
      <Container>
        <Row>
          <AppText droppedFilesLoaded={droppedFilesLoaded} />
          <DevTools batchConfig={batchConfig} setBatchConfig={setBatchConfig} />
        </Row>
        {!droppedFilesLoaded && (
          <Row>
            <Col md="9">
              <Dropzone onDropped={filesDropped} />
            </Col>
            <Col md="3">
              <GPUDetection />
            </Col>
          </Row>
        )}
        {droppedFilesLoaded && !inferenceStarted && (
          <Row>
            <Col sm="12">
              <ProcessDetails
                files={droppedFiles}
                setDroppedFiles={setDroppedFiles}
                disableStart={!isModelSet}
                setReferenceFile={setReferenceFile}
              />
            </Col>
          </Row>
        )}
        {droppedFilesLoaded && inferenceStarted && (
          <Row>
            <Col sm="12">
              <UnloadHandler>
                <BatchProgress
                  files={droppedFiles}
                  batchConfig={batchConfig}
                  appStatus={appStatus}
                  referenceFile={referenceFile}
                />
              </UnloadHandler>
            </Col>
          </Row>
        )}
        <Row>
          <HowItWorks />
        </Row>
      </Container>
      <Container fluid className="px-0 overflow-hidden">
        <Row>
          <WorkPrivate />
        </Row>
      </Container>
      <Container>
        <Row>
          <AnyQuestions />
        </Row>
      </Container>
    </div>
  );
};

Dashboard.propTypes = {
  backend: PropTypes.string,
  library: PropTypes.string,
  decoder: PropTypes.string,
  processingStarted: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    appStatus: getAppStatus(state),
  };
};

export default connect(mapStateToProps)(Dashboard);
