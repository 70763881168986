import * as Comlink from 'comlink';

import GlobalConfig from 'Config';
import Profiler from '../profiler';
import { Storage } from 'Services';

/**
 * Create a new Bascaller instance
 * @class
 */
export default class Basecaller {
  classes = ['N', 'A', 'C', 'G', 'T'];

  /**
   * Run on class instantiation
   * @constructor
   */
  constructor(libraryName = 'Tensorflow', model = {}, backend = 'webgl', updateProgress = () => {}) {
    this.backend = backend;
    this.library = libraryName;
    this.model = model;
    this.workers = {
      inference: {},
      decoder: {},
      file: {},
    };
    this.updateProgress = updateProgress;
  }

  async loadWorkers(backend = this.backend, library = this.library, workerList = GlobalConfig.services.workers) {
    for (const workerType in workerList) {
      for (let i = 1; i <= workerList[workerType]; i++) {
        let worker = null;
        if (workerType === 'inference') {
          worker = new Worker(`../webworker/inference.js`, { type: 'module' });
        } else if (workerType === 'decoder') {
          worker = new Worker(`../webworker/decoder.js`, { type: 'module' });
        } else if (workerType === 'file') {
          worker = new Worker(`../webworker/file.js`, { type: 'module' });
        }

        const readStoreWorker = new SharedWorker('../webworker/readStore.js', { type: 'module', name: 'readStore' });
        const WorkerProxy = Comlink.wrap(worker);

        this.workers[workerType][`worker${i}`] = {
          workerRef: worker,
          instance: await new WorkerProxy(
            Comlink.transfer(readStoreWorker.port, [readStoreWorker.port]),
            backend,
            library,
          ),
          inUse: false,
        };
      }
    }
  }

  terminateWorkers(workerList = GlobalConfig.services.workers) {
    for (const workerType in workerList) {
      for (let i = 1; i <= workerList[workerType]; i++) {
        this.workers[workerType][`worker${i}`].instance.closeReadStore(true);
        this.workers[workerType][`worker${i}`].workerRef.terminate();
      }
    }
    this.workers = {
      inference: {},
      decoder: {},
      file: {},
    };
  }

  async warmupModel(tensorSize, batchSize) {
    for (const worker in this.workers.inference) {
      await this.workers.inference[worker].instance.warmupModel(tensorSize, batchSize);
    }
  }

  async loadModel(updateUi = true) {
    const { path, name } = this.model;

    const indexedDB = await new Storage['IndexedDB'](this.library);
    const isModelAvailbleOffline = (await indexedDB.getModelKeys()).includes(name);
    if (!isModelAvailbleOffline && updateUi) {
      this.updateProgress({ status: 'downloading' });
    }
    for (const worker in this.workers.inference) {
      await this.workers.inference[worker].instance.session.loadModel(path, name, isModelAvailbleOffline);
    }
  }

  async waitUntil(workerType) {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        const worker = Object.keys(this.workers[workerType]).find((key) => !this.workers[workerType][key].inUse);
        if (worker) {
          resolve(worker);
          clearInterval(interval);
        }
      }, 50);
    });
  }

  async loadFile(file) {
    const workerIdx = await this.waitUntil('file');
    const profilerOffset = Profiler.getHRTS();
    this.workers.file[workerIdx].inUse = true;

    const { files, profilerTrace } = await this.workers.file[workerIdx].instance.loadFile(
      file,
      profilerOffset,
      workerIdx,
    );

    Profiler.traceEvents.push(...profilerTrace);
    this.workers.file[workerIdx].inUse = false;

    return files;
  }

  async preloadBatch(amount, reads, config, batches) {
    const workerIdx = await this.waitUntil('file');
    const profilerOffset = Profiler.getHRTS();
    this.workers.file[workerIdx].inUse = true;

    const {
      batches: batchList,
      reads: readList,
      samples,
      profilerTrace,
    } = await this.workers.file[workerIdx].instance.preloadBatch(
      amount,
      reads,
      config,
      batches,
      profilerOffset,
      workerIdx,
    );

    Profiler.traceEvents.push(...profilerTrace);
    this.workers.file[workerIdx].inUse = false;

    return { batches: batchList, reads: readList, samples };
  }

  async createAndRunTensors(batch) {
    try {
      const workerIdx = await this.waitUntil('inference');
      this.workers.inference[workerIdx].inUse = true;
      const { samples, tensorSize, batchSize, batchIdx, batchMetaData, modelStride, overlapAmount } = batch;
      const profilerOffset = Profiler.getHRTS();
      const profilerTrace = await this.workers.inference[workerIdx].instance.createAndRunTensors(
        samples.splice(0),
        tensorSize,
        batchSize,
        batchMetaData,
        modelStride,
        overlapAmount,
        profilerOffset,
        workerIdx,
      );
      Profiler.traceEvents.push(...profilerTrace);

      this.workers.inference[workerIdx].inUse = false;
      return { batchMetaData, batchIdx };
    } catch (e) {
      throw e;
    }
  }

  async decodeBatch(batchMetaData, filename, modelStride, features) {
    const workerIdx = await this.waitUntil('decoder');
    this.workers.decoder[workerIdx].inUse = true;
    const profilerOffset = Profiler.getHRTS();
    const profilerTrace = await this.workers.decoder[workerIdx].instance.decodeScores(
      batchMetaData,
      filename,
      modelStride,
      features,
      profilerOffset,
      workerIdx,
    );
    Profiler.traceEvents.push(...profilerTrace);
    this.workers.decoder[workerIdx].inUse = false;
  }
}
