import React, { useEffect } from 'react';

const UnloadHandler = ({ children }) => {
  const handler = (e) => {
    let confirmationMessage = "Closing this tab will stop basecalling and you'll lose all progress that's been made.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE

    return confirmationMessage; //Webkit, Safari, Chrome
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return <>{children}</>;
};

export default UnloadHandler;
