/**
 * Creates a new localStorage service
 * @class
 */
export default class LocalStorage {
  /**
   * Get cached data
   * @param  {string} key Item key
   * @param  {boolean} isJson Should get as json parsed?
   * @return {object}      Cached data
   * @method
   */
  static get(key = '', isJson = true) {
    const data = localStorage.getItem(key);
    if (data !== null && data) return isJson ? JSON.parse(data) : data;
    return null;
  }

  /**
   * Set cache for data
   * @param  {string} key Item key
   * @param  {object} data Item data
   * @param  {boolean} asJson Should set as json?
   * @return {object}      Cached data
   * @method
   */
  static set(key = '', data = {}, asJson = true) {
    const parsedData = asJson ? JSON.stringify(data) : data;
    localStorage.setItem(key, parsedData);
  }

  /**
   * Check if cached item exists
   * @param  {string} key Item key
   * @return  {boolean} Key exists
   * @method
   */
  static exists(key = '') {
    return localStorage.getItem(key) && true;
  }

  /**
   * Delete cache associated with that key
   * @param  {string} key Item key
   * @method
   */
  static remove(key = '') {
    localStorage.setItem(key, null);
  }

  /**
   * Delete all cache
   * @method
   */
  static clean() {
    localStorage.clear();
  }
}
