import React from 'react';

export const pathToPageTitle = (path) => {
  const basePath = path.replace('/', '').toLowerCase();
  return basePath.charAt(0).toUpperCase() + basePath.substring(1);
};

export const getPageTemplate = (PageTemplates, templateName) => {
  if (!templateName || templateName === '') templateName = 'Dashboard';
  if (!(templateName in PageTemplates)) templateName = 'Dashboard';

  const Template = PageTemplates[templateName];
  return Template && <Template title={templateName} />;
};

export const i18n = (text) => text;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const CalculateDuration = (timestamp) => {
  const timeDifference = new Date().valueOf() - timestamp;
  const secondsInMiliseconds = 1000;
  const minutesInMiliseconds = 60 * secondsInMiliseconds;
  const hoursInMiliseconds = 60 * minutesInMiliseconds;

  const differenceInHours = timeDifference / hoursInMiliseconds;
  const differenceInMinutes = (differenceInHours % 1) * 60;
  const differenceInSeconds = (differenceInMinutes % 1) * 60;

  return {
    hours: Math.floor(differenceInHours),
    minutes: Math.floor(differenceInMinutes),
    seconds: Math.floor(differenceInSeconds),
  };
};
