import React from 'react';

import './Notfound.scss';

const Notfound = () => {
  return (
    <div className="notfound">
      <p>404 Not Found</p>
    </div>
  );
};

Notfound.propTypes = {};

Notfound.defaultProps = {};

export default Notfound;
