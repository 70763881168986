import './NotSupportedModal.scss';

import React, {useState} from 'react';

import { ActionButton } from 'Components';
import Modal from 'react-modal';
import { i18n } from 'Utils';

const NotSupportedModal = () => {
  const [modalIsOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }
  
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-container not-supported-modal"
      overlayClassName="modal-overlay"
      appElement={document.querySelector('#root')}
    >
      
      <h5 className='font-bold'>{i18n('Incompatible browser detected')}</h5>
      <p>{i18n('Safari does not support all technologies required for browser basecalling and is therefore not supported.')}</p>
      <p>{i18n('Using either Google Chrome or Microsoft Edge is recommended.')}</p>
      
      <div className="text-center">
        <ActionButton classname="cancel-button" onClick={closeModal} text={i18n('Close')} />
      </div>
    </Modal>
  );
};

export default NotSupportedModal;
