import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { i18n } from 'Utils';
import { ActionButton, ModelSelectionMain, ModelSelectionTitle } from 'Components';
import { circleTick } from 'Images';

import './ModelModal.scss';

const getAvailableFlowCell = (models) =>
  models.reduce((acc, { flowcell }) => {
    return [...new Set([...acc, ...flowcell])];
  }, []);

const getAvailableKits = (models, { flowcell: selectedFlowcell }) =>
  models.reduce((acc, { flowcell, kit }) => {
    let kits = acc;
    if (flowcell.includes(selectedFlowcell)) kits = [...new Set([...acc, ...kit])];
    return kits;
  }, []);

const getAvailableAlgorithms = (availableModels, { flowcell, kit }) =>
  availableModels
    .filter((model) => model.flowcell.includes(flowcell) && model.kit.includes(kit))
    .map((model) => model.algorithm);

const ModelModal = ({ availableModels, closeModal, setModel, defaultModel, setDefaultModel }) => {
  const [activeSection, setActiveSection] = useState('flowcell');
  const [currentSelection, setCurrentSelection] = useState({});

  const memoizedFlowcell = React.useMemo(() => getAvailableFlowCell(availableModels), [availableModels]);
  const availableKits = React.useMemo(
    () => getAvailableKits(availableModels, currentSelection),
    [availableModels, currentSelection],
  );
  const memoizedAlgorithms = React.useMemo(
    () => getAvailableAlgorithms(availableModels, currentSelection),
    [availableModels, currentSelection],
  );

  const setSelection = (selection, section) => {
    setCurrentSelection((s) => ({ ...s, ...selection }));
    section && setActiveSection(section);
  };

  const updateModelSelection = () => {
    const { flowcell, kit, algorithm } = currentSelection;
    const selectedModel = availableModels.find(
      (model) => model.flowcell.includes(flowcell) && model.kit.includes(kit) && model.algorithm.includes(algorithm),
    );
    setModel(selectedModel);
    closeModal();
  };

  const { flowcell, algorithm, kit } = currentSelection;
  const modelSelected = !!flowcell && !!kit && !!algorithm;

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      className="modal-container model-modal"
      overlayClassName="modal-overlay"
      appElement={document.querySelector('#root')}
    >
      <h3 className="mb-5">{i18n('Basecalling model')}</h3>
      <a href="#" className="model-modal__close" onClick={closeModal} />

      {activeSection === 'flowcell' && (
        <>
          <ModelSelectionMain title="Which flow cell and kit to you use?" number={1}>
            <div className="model-model__section_content mt-4">
              {memoizedFlowcell.map((item, idx) => {
                const isActive = item === flowcell;
                return (
                  <ActionButton
                    key={`section1-${idx}`}
                    classname={`model-model__section_button model-button ${isActive ? 'active' : ''}`}
                    onClick={() => setSelection({ flowcell: item }, 'kit')}
                    text={item}
                    icon={isActive && circleTick}
                  />
                );
              })}
            </div>
          </ModelSelectionMain>

          <ModelSelectionTitle number={2} section={activeSection} />
        </>
      )}

      {activeSection === 'kit' && (
        <>
          <ModelSelectionMain title="Which flow cell and kit to you use?" number={1}>
            <div className="model-model__section_content mt-4">
              {availableKits.map((item, idx) => {
                const isActive = item === kit;
                return (
                  <ActionButton
                    key={`section1-${idx}`}
                    classname={`model-model__section_button model-button ${isActive ? 'active' : ''}`}
                    onClick={() => setSelection({ kit: item }, 'algorithm')}
                    text={item}
                    icon={isActive && circleTick}
                  />
                );
              })}
            </div>
          </ModelSelectionMain>

          <ModelSelectionTitle number={2} section={activeSection} />
        </>
      )}

      {activeSection === 'algorithm' && (
        <>
          <ModelSelectionTitle
            number={1}
            section={activeSection}
            onClick={() => setSelection({ algorithm: undefined }, 'flowcell')}
            flowcell={flowcell}
            kit={kit}
          />

          <ModelSelectionMain title="Available basecalling algorithms" number={2}>
            <p className="mt-2">{i18n('Based on your choice of flow cell and kit, these algorithms are available.')}</p>
            <div className="model-model__section_content single-col mt-4">
              {memoizedAlgorithms.map((item, idx) => (
                <ActionButton
                  key={`section1-${idx}`}
                  classname={`model-model__section_button model-button ${item === algorithm ? 'active' : ''}`}
                  onClick={() => setSelection({ algorithm: item })}
                  text={item}
                  icon={item === algorithm && circleTick}
                />
              ))}
            </div>
            <p className="mt-4 mb-0">
              {i18n('More accurate algorithms are more computationally intensive. Read more at ')}
              <a href="//ont.tips/basecall-algs" rel="noreferrer" target="_blank">
                ont.tips/basecall-algs
              </a>
              .
            </p>
          </ModelSelectionMain>
        </>
      )}

      <div className="model-modal__save">
        <div className="model-modal__save_details">
          <p className="font-700 mb-1">
            {i18n('Selected model:')}{' '}
            <span className="font-400">{modelSelected ? `${flowcell} - ${kit} - ${algorithm}` : 'None selected'}</span>
          </p>
          <label htmlFor="default-modal">
            {i18n('Use as default in the future')}
            <input
              className="ms-2"
              type="checkbox"
              name="default-modal"
              checked={defaultModel}
              onChange={() => setDefaultModel((s) => !s)}
            />
          </label>
        </div>
        <ActionButton
          classname={modelSelected ? 'save-button' : 'stopped-button'}
          onClick={updateModelSelection}
          text="Use this model"
          disabled={!modelSelected}
        />
      </div>
    </Modal>
  );
};

ModelModal.propTypes = {
  availableModels: PropTypes.array,
  closeModal: PropTypes.func,
  model: PropTypes.object,
  defaultModel: PropTypes.bool,
  setDefaultModel: PropTypes.func,
};

export default ModelModal;
