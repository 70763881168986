import { UPDATE_DEVICE_INFO } from '../../actions/actionTypes';
import GlobalConfig from 'Config';

export const deviceInfoReducer = (state = GlobalConfig.initialState.device, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DEVICE_INFO:
      return {
        deviceInfo: payload ,
      };
    default:
      return state;
  }
};
