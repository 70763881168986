import React from 'react';
import PropTypes from 'prop-types';
import Router from './router';

const App = () => {
  return <Router />;
};

App.propTypes = {
  actions: PropTypes.shape(),
};

App.defaultProps = {
  actions: null,
};

export default App;
