import './FileTypeSelect.scss';

import { getFileExtension, setConfigOptions } from 'Actions';

import { CustomOption } from 'Components';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

const FileTypeSelect = ({  fileExtension, setConfigOptions }) => {
 

  const setFileExtension = (value) => setConfigOptions({ fileExtension: value });

  const fileExtensions = [
    { value: '.bam', name: 'BAM' },
    { value: '.sam', name: 'SAM' },
  ]

  return (
    <div className="file-type-select-wrapper">

      <div className="file-type-select-wrapper__inputs">
        <div className="file-type-select-wrapper__dropdown" data-testid="file-type-select">
          <Select
            value={fileExtension}
            onChange={setFileExtension}
            options={fileExtensions}
            components={{ Option: CustomOption }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option}
          />
        </div>
      </div>
    </div>
  );
};

FileTypeSelect.propTypes = {
  fileExtension: PropTypes.object,
  setConfigOptions: PropTypes.func,
};


const mapStateToProps = (state) => {
  return {
    fileExtension: getFileExtension(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfigOptions: (options) => dispatch(setConfigOptions(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileTypeSelect);
