import React from 'react';
import { logo } from 'Images';
import { Navbar } from 'reactstrap';
import { Configuration, Container, Row, Col } from 'Components';
import { Link } from 'react-router-dom';
// import { i18n } from 'Utils';
import GlobalConfig from 'Config';

import './Header.scss';

const Header = () => {
  return (
    <header>
      <Container>
        <Row>
          <Col sm="12">
            <Navbar light>
              <a href={`${process.env.PUBLIC_URL}/`}>
                <img src={logo} className="navbar__logo" />
              </a>
              <span className="navbar__basetext">
                Basecaller{' '}
                <span className="version">
                  <Link to="/change-log">v{GlobalConfig.version}</Link>
                </span>
              </span>
              <Configuration />
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
