import React from 'react';
import { i18n } from 'Utils';

import './ProcessTitle.scss';

const ProcessTitle = ({ files, renderItem }) => {
  const filenames = files.map((file) => file.name).join(', ');
  return (
    <div className="process-title px-3 py-4">
      <h4 className="mb-0 overflow-hidden">
        <span>{filenames}</span>{' '}
        <span>
          ({files.length} {i18n('files')})
        </span>
      </h4>
      {renderItem()}
    </div>
  );
};

export default React.memo(ProcessTitle);
