import { AutoBatchSelector, Basecaller, Batch } from 'Services';

import GlobalConfig from 'Config';
import { UPDATE_INFERENCE_STATUS } from '../actionTypes';
import { logger } from 'Utils/logger';

let basecallRunner = null;
let filePromise = null;
let batchSelector = null;

const instantiateBatchSelector = (tensorSize) => {
  if (batchSelector === null) {
    batchSelector = new AutoBatchSelector(basecallRunner, tensorSize);
  }
};

export const getInferenceStatus = (state) => state.inferenceReducer.inferenceStatus;

const createUpdateAction =
  (dispatch) =>
  (data = {}) => {
    dispatch({
      type: UPDATE_INFERENCE_STATUS,
      payload: data,
    });
  };

export const loadBasecaller = (type, model, backend) => async (dispatch) => {
  basecallRunner = new Basecaller(type, model, backend, createUpdateAction(dispatch));
};

export const configureBasecaller = (modelName) => async (dispatch) => {
  await benchmarkAndSetConfig(modelName, dispatch);
  await basecallRunner.loadWorkers();
  await basecallRunner.loadModel();
};

const benchmarkAndSetConfig = async (modelName, dispatch) => {
  const savedWorkerConfig = localStorage.getItem(modelName);

  if (!savedWorkerConfig) {
    const updateProgress = createUpdateAction(dispatch);
    updateProgress({ status: 'benchmarking' });
    const { tensorSize } = GlobalConfig.mlconfig;
    await runBenchmark(tensorSize)();
  } else {
    const performanceConfig = JSON.parse(savedWorkerConfig);
    GlobalConfig.services.workers = performanceConfig.workers;
  }
};

export const getBatchSize = (modelName) => {
  const savedWorkerConfig = localStorage.getItem(modelName);
  const performanceConfig = JSON.parse(savedWorkerConfig);
  return performanceConfig?.batchSize || GlobalConfig.mlconfig.batchSize;
};

export const runInference = (files, config, referenceFile) => async (dispatch, state) => {
  let fileNames = new Set();
  let hasDuplicates = files.some((file) => fileNames.size === fileNames.add(file.name).size);
  if (hasDuplicates) {
    throw new Error('File names contain duplicates');
  }

  const getProgress = () => getInferenceStatus(state());
  const updateProgress = createUpdateAction(dispatch);
  try {
    // const { tensorSize, batchSize } = config;
    // await basecallRunner.session.warmupModel(basecallRunner.createShape(tensorSize, batchSize));
    const batch = new Batch(basecallRunner, config, updateProgress, getProgress);

    filePromise = await batch.executeFiles(files, referenceFile);
    return filePromise;
  } catch (e) {
    logger.dump('mlerror', e);
    const data = { error: true };

    if (e === 'NotImplementedError("Filter with id:32020 not supported")') {
      data.vbzError = true;
    } else if (e.toString().indexOf('Incorrect file signature:') > -1) {
      data.gzipError = true;
    } else if (e.toString().indexOf('TypeError: e is not a function') > -1) {
      data.badBatchError = true;
    }
    updateProgress(data);
  }
};

export const runBenchmark = (tensorSize) => async () => {
  instantiateBatchSelector(tensorSize);
  return await batchSelector.runBenchmark();
};

export const triggerDownload = () => async (dispatch) => {
  const updateProgress = createUpdateAction(dispatch);
  updateProgress({ status: 'preparingforDownload' });
  await filePromise.downloadFiles(GlobalConfig.downloadProfiling);

  updateProgress({ status: 'completed' });
};

export const savePerformanceConfig = (workerConfig) => {
  const { workers, batchSize, name } = workerConfig;
  const performanceConfig = JSON.stringify({
    workers,
    batchSize,
  });
  localStorage.setItem(name, performanceConfig);
  GlobalConfig.services.workers = workers;
};

export const stopInference = () => async (dispatch) => {
  const updateProgress = createUpdateAction(dispatch);

  updateProgress({ active: false, status: 'stopped' });
};
