/**
 * Device Stats
 * @class
 */

/**
 * Full device info
 */
export default () => {
  return {
    gpu: gpuInfo(),
    cpu: {
      cores: cpuCores(),
      freq: cpuFreq(),
    },
    ram: {
      size: ramSize(),
    },
    webgpu: webGPU(),
  };
};

/**
 * Clear canvas element
 * @param {object} canvas Canvas element
 */
const clearCanvas = (canvas) => {
  canvas.remove();
  canvas = null;
};

/**
 * Current GPU information
 * @returns {object} GPU information
 */

export const gpuInfo = () => {
  const canvas = document.createElement('canvas');
  const wgl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  if (wgl) {
    const debugInfo = wgl.getExtension('WEBGL_debug_renderer_info');

    if (debugInfo) {
      const gpuInfo = {
        GPUDetected: true,
        vendor: wgl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
        renderer: wgl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
      };

      clearCanvas(canvas);
      return gpuInfo;
    }
  }

  clearCanvas(canvas);
  return { GPUDetected: false };
};

/**
 * CPU cores available
 * @returns {number} Number of CPU cores detected
 */
export const cpuCores = () => {
  return navigator.hardwareConcurrency;
};

/**
 * RAM available
 * @returns {number} Size of ram in GB
 */
export const ramSize = () => {
  return navigator.deviceMemory;
};

export const webGPU = () => {
  return navigator.gpu || null;
};

/**
 * CPU frequency amount
 * JSBenchmark by Aaron Becker
 * @returns {number} Extimated cpu frequency in GHz
 */
export const cpuFreq = () => {
  const _speedconstant = 8.9997e-9; //if speed=(c*a)/t, then constant=(s*t)/a and time=(a*c)/s
  const d = new Date();
  const amount = 150000000;

  for (var i = amount; i > 0; i--) {} //eslint-disable-line

  const newd = new Date();
  const accnewd = Number(String(newd.getSeconds()) + '.' + String(newd.getMilliseconds()));
  const accd = Number(String(d.getSeconds()) + '.' + String(d.getMilliseconds()));
  var di = accnewd - accd;

  if (d.getMinutes() != newd.getMinutes()) di = 60 * (newd.getMinutes() - d.getMinutes()) + di;

  const spd = (_speedconstant * amount) / di;
  return (Math.round(spd * 1000) / 1000) * 2;
};
