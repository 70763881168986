import React from 'react';
import './WorkPrivate.scss';
import WPCard from '../../molecules/WPCard';
import { lock, offline } from 'Images';
import { Container } from 'Components';

const WorkPrivate = () => {
  return (
    <div className="wp-cards">
      <Container className="wp-cards__container d-flex">
        <WPCard
          image={lock}
          title="Work Offline"
          description="After you've selected your model, the basecaller runs locally on your machine, so you don't need to stay connected to the internet."
        />
        <WPCard
          image={offline}
          title="Private & Secure"
          description="Dragging a file in to this tab doesn't send it anywhere. Everything stays on your machine and no data is transferred."
        />
      </Container>
    </div>
  );
};

export default React.memo(WorkPrivate);
