import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { i18n } from 'Utils';
import { deviceInfo, getGPUDetectedStatus, getDeviceRenderer } from 'Actions';
import { microchipRed, microchipGreen } from 'Images';
import { bindActionCreators } from 'redux';
import store from '../../../redux/store';

import './StaticGPUDetection.scss';

const GPUDetection = ({ gpuDetected, deviceName }) => {
  useEffect(() => {
    if (deviceName == null) {
      actions.deviceInfo();
    }
  }, []);

  const gpuClass = classNames({
    'staticgpuDetection': true,
    'active': gpuDetected,
    'px-3': true,
  });

  let title = 'GPU not detected';
  let icon = microchipRed;

  if (gpuDetected) {
    title = 'GPU: ';
    icon = microchipGreen;
  }

  return (
    <div className={gpuClass}>
      <img src={icon} className="gpuicon" />
      <h5 className="mb-0">
        {i18n(title)}
        {i18n(deviceName)}
      </h5>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gpuDetected: getGPUDetectedStatus(state),
    deviceName: getDeviceRenderer(state),
  };
};

export default connect(mapStateToProps)(GPUDetection);

const actions = bindActionCreators({ deviceInfo }, store.dispatch);

GPUDetection.propTypes = {
  gpuDetected: PropTypes.bool,
};
