import React from 'react';
import { Link } from 'react-router-dom';
import { i18n } from 'Utils';
import './WebGPUButton.scss';

const WebGPUButton = ({ image, text }) => {
  return (
    <Link style={{ textDecoration: 'none' }} to="/">
      <div className="webgpubutton d-flex mb-4">
        <img src={image} className="webgpubutton__image" />
        <p className="webgpubutton__text mb-0">{i18n(text)}</p>
      </div>
    </Link>
  );
};

export default WebGPUButton;
