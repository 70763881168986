import fileDownload from 'js-file-download';
import SamFile from '../sam/';

import Aioli from '@biowasm/aioli';

import { samExtension, bamExtension } from '../constants';


export default class BamFile extends SamFile {
  constructor(filename, clearDB = true) {
    super(filename, clearDB, bamExtension);
  }

  async convertToBam(fileData) {
    const strJoin = window.location.pathname.endsWith('/') ? '' : '/';
    const biowasmPath = `${window.location.origin}${window.location.pathname}${strJoin}biowasm`;

    const aioliCLI = await new Aioli({
      tool: 'samtools',
      version: '1.10',
      program: 'samtools',
      urlPrefix: `${biowasmPath}/samtools`,
    });

    const str2blob = (txt) => new Blob([txt]);
    const samBlob = str2blob(fileData);

    const files = [{ name: this.filename.split('.')[0] + samExtension, data: samBlob }];
    // The function `.mount()` returns the absolute paths of each file mounted
    await aioliCLI.mount(files);

    await aioliCLI.exec(`samtools view -S -b ${files[0].name} -o output.bam`);
    return await aioliCLI.fs.readFile('./output.bam', { encoding: 'binary' });
  }

  async downloadFile(referenceFile, summaryFile) {
    let fileData;

    if (referenceFile) {
      fileData = await this.generateAlignedContent(referenceFile, summaryFile);
    } else {
      fileData = await this.generateUnalignedContent(summaryFile);
    }

    fileData = await this.convertToBam(fileData);

    await fileDownload(fileData, this.filename);
  }
}
