import React, { useEffect, useState } from 'react';
import { CalculateDuration } from 'Utils';

const Duration = ({ timestamp, inferenceCompleted, inferenceActive }) => {
  const [duration, setDuration] = useState({ hours: 0, minutes: 0, seconds: 0 });
 
  useEffect(() => {
    const interval = setInterval(() => setDuration(CalculateDuration(timestamp)), 1000);
    if (inferenceCompleted || !inferenceActive) clearInterval(interval);
    return () => {
      clearInterval(interval);
    };
  }, [timestamp, inferenceActive, inferenceCompleted]);

  const { hours, minutes, seconds } = duration;
  return (
    <div className="duration-wrapper">
      <p className="mb-0">
        <span>{hours} hours</span> <span>{minutes} minutes</span> <span>{seconds} seconds</span>
      </p>
    </div>
  );
};

export default Duration;
