import Dexie from 'dexie';

class IndexedDB {
  constructor(library = 'Tensorflow', dbName) {
    this.db;
    this.library = library;
    if (dbName) {
      this.db = new Dexie(dbName);
      this.db.version(1).stores({ reads: 'id' });
    }
  }

  async clear() {
    await this.db.reads.clear();
  }

  async setItem(key, value) {
    return await this.db.reads.add({ id: key, data: value });
  }

  async bulkAdd(data, keys) {
    return await this.db.reads.bulkAdd(data, keys);
  }

  async keys() {
    return await this.db.reads.toCollection().keys();
  }

  async getItem(key) {
    const item = await this.db.reads.get(key);
    if (item) return item.data || item;
  }

  static deleteDB(dbName) {
    window.indexedDB.deleteDatabase(dbName);
  }

  async _initializeDBConfig() {
    if (!this.initializationDB) {
      this.initializationDB = await this.configureDatabase();
    }
  }

  configureDatabase = async () => {
    if (this.library === 'Tensorflow') {
      const databaseExists = (await window.indexedDB.databases()).map((db) => db.name).includes('tensorflowjs');

      // Only connect once the DB exists otherwise tensorflow will throw an error
      if (databaseExists) {
        this.db = await this.idxDBPromise(window.indexedDB.open('tensorflowjs', 1));
      }
      this.store = 'models_store';
    }
    return true;
  };

  getModelKeys = async () => {
    try {
      await this._initializeDBConfig();
      const objectStore = this.db.transaction(this.store, 'readonly').objectStore(this.store);
      return this.idxDBPromise(objectStore.getAllKeys());
    } catch (e) {
      return [];
    }
  };

  idxDBPromise = (input) =>
    new Promise((resolve, reject) => {
      input.onsuccess = (event) => {
        resolve(event.target.result);
      };

      input.onerror = (event) => {
        reject(event);
      };
    });
}

export { IndexedDB };
