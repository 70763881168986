import './BatchProgress.scss';

import {
  ActionButton,
  ActionModal,
  Duration,
  ErrorModal,
  ProcessRow,
  ProcessTitle,
  ProgressBar,
  ProgressList,
  TotalReads,
} from 'Components';
import React, { useEffect, useState } from 'react';
import {
  configureBasecaller,
  getBatchSize,
  getFileExtension,
  getInferenceStatus,
  loadBasecaller,
  runInference,
  stopInference,
  triggerDownload,
} from 'Actions';

import { connect } from 'react-redux';
import { i18n } from 'Utils';
import { sendTrackingData } from 'Utils';

const BatchProgress = ({
  files,
  inferenceStatus,
  batchConfig,
  appStatus,
  loadBasecaller,
  configureBasecaller,
  runInference,
  stopInference,
  referenceFile,
  triggerDownload,
  fileExtension,
}) => {
  const { status, error, vbzError, gzipError, badBatchError, percent, timestamp, totalReads, totalSamples } =
    inferenceStatus;

  const { backend, library, model } = appStatus;
  const [showActionModal, toggleActionModal] = useState(false);
  const [showErrorModal, toggleErrorModal] = useState(false);

  useEffect(async () => {
    await loadBasecaller(library, model, backend);
    await configureBasecaller(model.name);
    const batchSize = getBatchSize(model.name);

    runInference(files, { ...batchConfig, batchSize, fileExtension }, referenceFile);
  }, []);

  useEffect(() => {
    if (error) toggleErrorModal(true);
  }, [error]);

  useEffect(() => {
    if (status === 'completed') {
      const runTime = Math.floor((new Date().valueOf() - timestamp) / 1000);
      const sampleSizePerSeconds = Math.floor(totalSamples / runTime);
      sendTrackingData([
        { name: 'Total Run Time', data: { runTime } },
        { name: 'Sample Size per second', data: { sampleSizePerSeconds } },
      ]);
    }
  }, [status, timestamp]);

  const active = status === 'active';
  const inferenceCompleted = percent === 100;
  const inferenceActive = active && !error;
  const hideContent = ['preparing', 'downloading', 'preparingforDownload'].indexOf(status) > -1;

  let actionProps = {};
  switch (status) {
    case 'downloading':
      actionProps = {
        classname: 'disabled-button',
        text: 'Downloading model...',
        disabled: true,
      };
      break;
    case 'stopped':
      actionProps = {
        classname: 'disabled-button',
        text: 'Stopped',
        disabled: true,
      };
      break;
    case 'completed':
      actionProps = {
        classname: 'proceed-button',
        onClick: () => {
          triggerDownload();
        },
        text: 'Download',
      };
      break;
    case 'preparingforDownload':
      actionProps = {
        classname: 'disabled-button',
        text: 'Preparing Download...',
        disabled: true,
      };
      break;
    case 'preloadingReads':
      actionProps = {
        classname: 'disabled-button',
        text: 'Preloading Reads...',
        disabled: true,
      };
      break;
    case 'benchmarking':
      actionProps = {
        classname: 'disabled-button',
        text: 'Benchmarking...',
        disabled: true,
      };
      break;
    case 'active':
      actionProps = {
        classname: 'stop-button',
        onClick: () => toggleActionModal((s) => !s),
        text: 'Stop Analysis',
      };
      break;
    default:
      actionProps = {
        classname: 'disabled-button',
        text: 'Preparing model...',
        disabled: true,
      };
  }

  const handleStop = (closeModel) => {
    stopInference();
    closeModel();
  };

  return (
    <div className="batch-progress">
      {showActionModal && (
        <ActionModal
          actionButton="Stop run"
          body="You'll lose all progress that’s been made."
          closeModal={() => toggleActionModal(false)}
          onClick={handleStop}
          title="Are you sure you want to stop the run?"
        />
      )}

      {showErrorModal && (
        <ErrorModal
          closeModal={() => toggleErrorModal(false)}
          onClick={handleStop}
          vbzError={vbzError}
          gzipError={gzipError}
          badBatchError={badBatchError}
        />
      )}

      <ProcessTitle files={files} renderItem={() => <ActionButton {...actionProps} />} />

      <div className={hideContent ? 'batch-progress__hide_content' : ''}>
        <ProcessRow
          title={i18n('Progress completed')}
          renderItem={() => <ProgressBar error={error} percent={percent} stopped={!active} />}
        />

        <ProcessRow
          title={i18n('Time elapsed')}
          renderItem={() => (
            <Duration timestamp={timestamp} inferenceCompleted={inferenceCompleted} inferenceActive={inferenceActive} />
          )}
        />

        <ProcessRow title={i18n('Reads')} renderItem={() => <TotalReads reads={totalReads} />} />

        <ProcessRow title={i18n('Batch steps')} renderItem={() => <ProgressList steps={inferenceStatus.steps} />} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    inferenceStatus: getInferenceStatus(state),
    fileExtension: getFileExtension(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runInference: (file, config, referenceFile) => dispatch(runInference(file, config, referenceFile)),
    stopInference: () => dispatch(stopInference()),
    loadBasecaller: (ibrary, model, backend) => dispatch(loadBasecaller(ibrary, model, backend)),
    configureBasecaller: (modelName) => dispatch(configureBasecaller(modelName)),
    triggerDownload: () => dispatch(triggerDownload()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchProgress);
