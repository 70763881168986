import fileDownload from 'js-file-download';
import { summaryExtension } from '../constants';
const newLineSeparator = '\n';
export default class SummaryFile {
  constructor(filename = 'summary', fileExtension = summaryExtension) {
    this.filename = filename;
    this.fileExtension = fileExtension;
    this.summaryRecords = new Map();
  }

  generateSummaryFileHeaders() {
    return [
      'filename',
      'read_id',
      'run_id',
      'channel',
      'mux',
      'start_time',
      'duration',
      'template_start',
      'template_duration',
      'sequence_length_template',
      'mean_qscore_template',
      'alignment_genome',
      'alignment_genome_start',
      'alignment_genome_end',
      'alignment_strand_start',
      'alignment_strand_end',
      'alignment_direction',
      'alignment_length',
      'alignment_num_aligned',
      'alignment_num_correct',
      'alignment_num_insertions',
      'alignment_num_deletions',
      'alignment_num_substitutions',
      'alignment_mapq',
      'alignment_strand_coverage',
      'alignment_identity',
      'alignment_accuracy',
    ];
  }

  addCommonData(readId, tags, meanQscore) {
    this.summaryRecords.set(readId, [
      tags.filename,
      readId,
      tags.run_id,
      tags.channel,
      tags.mux,
      tags.exp_start_time,
      tags.start,
      tags.exp_start_time,
      tags.start,
      tags.numSamples,
      meanQscore,
    ]);
  }

  addFillerRecord(readId) {
    let data = this.summaryRecords.get(readId);
    data = data.concat(['*', -1, -1, -1, -1, '*', 0, 0, 0, 0, 0, 0, 0, 0.0, 0.0, 0.0]);
    this.summaryRecords.set(readId, data);
    return data;
  }

  addAlignedData(readId, alignedRead, pafTags) {
    let existingRecord = this.summaryRecords.get(readId);
    // When reads go through alignment they are sometimes split
    // So we need to take into account the same readId appearing twice.
    const hasAlignmentData = existingRecord.length >= 12;
    if (hasAlignmentData) {
      existingRecord = existingRecord.slice(0, 11);
      readId = readId + alignedRead[3];
    }

    const cigarString = alignedRead[5];
    let ins = 0;
    let dels = 0;
    if (cigarString !== undefined) {
      ins = cigarString.split('1').length - 1;
      dels = cigarString.split('2').length - 1;
    }

    const subs = parseInt(alignedRead[11].match(/\d+/)[0], 10) - ins - dels;
    const length = parseInt(pafTags[10]);
    const matches = length - ins - dels;
    const correct = parseInt(pafTags[9]);
    const genomeName = alignedRead[2];
    const genomeStart = pafTags[7];
    const genomeEnd = pafTags[8];
    const strandStart = pafTags[2];
    const strandEnd = pafTags[3];
    const direction = pafTags[4];
    const mapq = alignedRead[4];
    const strandCoverage = (pafTags[3] - pafTags[2]) / alignedRead[9].length;
    const identity = correct / matches;
    const accuracy = correct / length;

    this.summaryRecords.set(
      readId,
      existingRecord.concat([
        genomeName,
        genomeStart,
        genomeEnd,
        strandStart,
        strandEnd,
        direction,
        length,
        matches,
        correct,
        ins,
        dels,
        subs,
        mapq,
        strandCoverage,
        identity,
        accuracy,
      ]),
    );
  }

  async downloadFile() {
    const csvData = [];

    for (const record of this.summaryRecords) {
      let recordData = record[1];
      const hasCommonDataOnly = record[1].length === 11;
      if (hasCommonDataOnly) {
        recordData = this.addFillerRecord(record[0]);
      }
      csvData.push(recordData);
    }
    const summaryData = this.generateSummaryFileHeaders() + newLineSeparator + csvData.join(newLineSeparator);
    await fileDownload(summaryData.replace(/\0/g, ''), this.filename + this.fileExtension);
  }
}
