import './AboutWebGPU.scss';

import React from 'react';
import WebGPUButton from '../../atoms/WebGPUButton';
import { gpuArrow } from 'Images';
import { i18n } from 'Utils';
import { webGpu } from 'Images';

const AboutWebGPU = () => {
  return (
    <div className="aboutwebgpu my-6">
      <div className="aboutwebgpu__button">
        <WebGPUButton image={gpuArrow} text="Return to Basecaller" />
      </div>
      <div className="aboutwebgpu__row d-flex">
        <div className="aboutwebgpu__description pe-4 col-8 ">
          <h3 className="aboutwebgpu__header mb-3">{i18n('WebGPU')}</h3>
          <p className="aboutwebgpu__text">
            {i18n('A new web standard and JavaScript API called')}
            <b> {i18n('WebGPU')} </b>{' '}
            {i18n(
              "is being developed to support using a machine's graphics processing unit (GPU) to assist when performing complex operations, such as DNA basecalling. It works by exposing a machine's native graphic processing capabilities for performing operations on the GPU.",
            )}
          </p>

          <p className="aboutwebgpu__text">
            {i18n(
              "ONT's Basecaller takes advantage of this cutting-edge technology to provide a way to basecall using a web broswer, in combination with our ML models that have been transpiled to work in a browser.",
            )}
          </p>

          <p className="aboutwebgpu__text">
            {i18n(
              'Please note: the basecaller can run using any GPU using WebGL, but to achieve the most performant results, we recommend using WebGPU.',
            )}{' '}
            <a href="#enablegpu">
              {' '}
              <b> {i18n('Find out how to enable it on your browser.')} </b>
            </a>
          </p>
        </div>
        <div className="aboutwebgpu__image col-4">
          <img src={webGpu} />
        </div>
      </div>
    </div>
  );
};

export default AboutWebGPU;
