import React from 'react';
import './GPUTimeline.scss';
import TimelineCard from '../../molecules/TimelineCard';
import { gpuIconWhite, gpuIconBlue } from 'Images';
import { i18n } from 'Utils';
import { Container } from 'Components';

const GPUTimeline = () => {
  return (
    <div className="timelineforsupport ">
      <Container className="timelineforsupport_container my-5">
        <h4 className="timelineforsupport__header my-3">{i18n('Timeline for Support')}</h4>
        <p className="mb-0">
          {i18n("As WebGPU is a new technology, it's not currently supported unless you enable it in developer-")}{' '}
        </p>
        <p>{i18n('specific releases. However, stable releases are planned for all major web browsers.')} </p>

        <div className="timelineforsupport__cards d-flex my-4">
          <TimelineCard
            image={gpuIconWhite}
            title="Today"
            description="All major browsers are implementing WebGPU, but it needs to be enabled using a flag on developer releases. Details of how to do that are available below for each major browser."
          />

          <TimelineCard
            image={gpuIconBlue}
            title="Q2 2023"
            description="WebGPU will be enabled for all users in Chrome 113, which is expected to release in Q2 2023. Other browsers are expected to be in the later stage of their trials."
          />

          <TimelineCard
            image={gpuIconBlue}
            title="Q4 2023"
            description="We expect all major browsers to have shipped support for WebGPU on stable channels, meaning the basecaller will be able to utilise your GPU with no extra configuration needed."
          />
        </div>
      </Container>
    </div>
  );
};

export default GPUTimeline;
