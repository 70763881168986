import * as math from 'mathjs';

import { dataSuffix, fast5Extension, fastqExtension, readGroupsKey, readTagsKey, samExtension } from '../constants';

import Aioli from '@biowasm/aioli';
import GlobalConfig from 'Config';
import { IndexedDB } from '../../storage';
import fileDownload from 'js-file-download';
const newLineSeparator = '\n';
const tabSeparator = '\t';
const __ont_bam_spec__ = '0.0.1';

export default class SamFile {
  constructor(filename = '', clearDB = true, fileExtension = samExtension) {
    if (filename == '') {
      filename = new Date().toLocaleString().replace(/\//g, '-').replace(', ', 'T').concat(fast5Extension);
    }
    this.filename = filename.substring(0, filename.indexOf(fast5Extension)) + fileExtension;
    this.db = null;
    this.initialiseData(clearDB);
  }

  async bulkAddReadTags(data) {
    await this.db.bulkAdd(data);
  }

  async addReadGroupsData(data) {
    await this.db.setItem(readGroupsKey, data);
  }

  removeNulls = (str) => str.replace(/\u0000|\x00/gi, ''); //eslint-disable-line

  meanQscoreFromQstring(qString) {
    if (qString.length === 0 || qString === '*') return 0.0;
    const encoder = new TextEncoder();
    const log = -math.log(10) / 10.0;
    const qs = [...encoder.encode(qString)].map((value) => (value - 33) * log); // 33 copied from bonito py code
    const mean = math.mean(math.exp(qs));
    return -10 * math.log10(math.max(mean, 1e-4));
  }

  async generateReadTags(readId, qString) {
    const tags = await this.db.getItem(`${readId}_${readTagsKey}`);
    let readTags = [];
    let meanQscore;
    if (tags) {
      let startTime = new Date(this.removeNulls(tags.exp_start_time));
      startTime.setSeconds(startTime.getSeconds() + tags.start);
      const startTimeStr = startTime.toISOString().split('.')[0];
      meanQscore = this.meanQscoreFromQstring(qString);
      readTags = [
        `RG:Z:${tags.run_id}_${tags.model}`,
        `qs:i:${Math.round(meanQscore)}`,
        `mx:i:${tags.mux}`,
        `ch:i:${tags.channel}`,
        `st:Z:${startTimeStr}`,
        `rn:i:${tags.read_number}`,
        `f5:Z:${tags.filename}`,
        `ns:i:${tags.numSamples}`,
        `ts:i:0`,
        `sm:f:${tags.shift}`,
        `sd:f:${tags.scale}`,
        `sv:Z:quantile`,
      ];
    }

    return { readTags, tags, meanQscore };
  }

  async generateReadGroups() {
    const readGroups = (await this.db.getItem(readGroupsKey)) || [];

    return readGroups.reduce((acc, { run_id, exp_start_time, flow_cell_id, device_id, sample_id, model }) => {
      acc.push(
        [
          '@RG',
          `ID:${run_id}_${model}`,
          `PL:ONT`,
          `DT:${exp_start_time.replace('Z', '')}`,
          `PU:${flow_cell_id}`,
          `PM:${device_id}`,
          `LB:${sample_id}`,
          `SM:${sample_id}`,
          `DS:run_id=${run_id} basecall_model=${model}`,
        ].join(tabSeparator),
      );
      return acc;
    }, []);
  }

  async generateHeaders() {
    const HD = ['@HD', 'VN:1.5', 'SO:unknown', `ob:${__ont_bam_spec__}`].join(tabSeparator);
    const PG1 = ['@PG', 'ID:basecaller', 'PN:bonito.epi2me.io', `VN:${GlobalConfig.version}`].join(tabSeparator);

    const readGroups = await this.generateReadGroups();

    const headers = [HD, PG1, ...readGroups].join(newLineSeparator) + newLineSeparator;
    return this.removeNulls(headers);
  }

  async getReadContent(read) {
    const readId = read.substring(0, read.indexOf(dataSuffix));
    const data = await this.db.getItem(`${readId}_data`);
    const sequence = data.sequence;
    const qString = data.qstring || '*';
    const { readTags, tags, meanQscore } = await this.generateReadTags(readId, qString);
    return { readId, sequence, qString, readTags, tags, meanQscore };
  }

  async alignWithMinimap(fastQContent, referenceFile, outputFormat) {
    const strJoin = window.location.pathname.endsWith('/') ? '' : '/';
    const biowasmPath = `${window.location.origin}${window.location.pathname}${strJoin}biowasm`;

    const aioliCLI = await new Aioli(
      {
        tool: 'minimap2',
        version: '2.22',
        program: 'minimap2',
        urlPrefix: `${biowasmPath}/minimap2`,
      },
      { printInterleaved: false },
    );

    const files = [
      referenceFile,
      { name: this.filename.split('.')[0] + fastqExtension, data: new Blob([fastQContent]) },
    ];

    // mount the files to the virtual file system
    await aioliCLI.mount(files);

    const outputFlag = outputFormat === 'sam' ? 'a' : 'c';

    const { stdout } = await aioliCLI.exec(`minimap2 -${outputFlag}x map-ont ${files[0].name} ${files[1].name}`);
    return stdout.split(newLineSeparator);
  }

  formatAlignedContent(headers, alignedContent, tags, alignedContentPAF, summaryFile) {
    const [minimapHeaders, reads] = alignedContent.reduce(
      (result, row) => {
        let data = row;
        const headerOrRead = row.startsWith('@') ? 0 : 1;
        if (headerOrRead === 1) {
          const read = data.split(tabSeparator);
          const readId = read[0];
          const readTags = tags[readId] || [];
          const alignedContentPafRead = alignedContentPAF.find((row) => {
            const pafTags = row.split(tabSeparator);
            const readIdPaf = pafTags[0];
            const isSameGenomeStart = parseInt(read[3]) === parseInt(pafTags[7]) + 1;
            return readId === readIdPaf && isSameGenomeStart;
          });

          if (alignedContentPafRead && readId) {
            summaryFile.addAlignedData(readId, read, alignedContentPafRead.split(tabSeparator));
          }

          data = [...read, ...readTags].join(tabSeparator);
        }

        result[headerOrRead].push(data);
        return result;
      },
      [[], []],
    );
    const combinedHeaders = headers + minimapHeaders.join(newLineSeparator);
    return combinedHeaders + newLineSeparator + reads.join(newLineSeparator);
  }

  async generateHeadersAndReadContent(callback) {
    const headers = await this.generateHeaders();
    const listOfValues = await this.db.keys();
    const records = [];
    const tags = {};

    for (const read of listOfValues) {
      if (read.includes(dataSuffix)) {
        const readContent = await this.getReadContent(read);
        callback(readContent, records, tags);
      }
    }

    return { headers, records, tags };
  }

  async generateAlignedContent(referenceFile, summaryFile) {
    const callback = (readContent, records, tags) => {
      const { readId, sequence, qString, readTags, meanQscore } = readContent;
      tags[readId] = readTags;
      const identifier = this.removeNulls([`@${readId}`, ...readTags].join(tabSeparator));
      records.push(identifier, sequence, ...(qString !== '*' ? ['+', qString] : []));
      summaryFile.addCommonData(readId, readContent.tags, meanQscore);
    };

    const { headers, records, tags } = await this.generateHeadersAndReadContent(callback);
    const fastQStr = records.join(newLineSeparator);
    const alignedContent = await this.alignWithMinimap(fastQStr, referenceFile, 'sam');
    const alignedContentPAF = await this.alignWithMinimap(fastQStr, referenceFile, 'paf');

    return await this.formatAlignedContent(headers, alignedContent, tags, alignedContentPAF, summaryFile);
  }

  async generateUnalignedContent(summaryFile) {
    const callback = (readContent, records) => {
      const { readId, sequence, qString, readTags, tags, meanQscore } = readContent;
      const samContent = [readId, 4, '*', 0, 0, '*', '*', 0, 0, sequence, qString, 'NM:i:0'];
      records.push(this.removeNulls([...samContent, ...readTags].join(tabSeparator)));
      summaryFile.addCommonData(readId, tags, meanQscore);
    };

    const { headers, records } = await this.generateHeadersAndReadContent(callback);

    return headers + records.join(newLineSeparator);
  }

  async downloadFile(referenceFile, summaryFile) {
    let fileData;

    if (referenceFile) {
      fileData = await this.generateAlignedContent(referenceFile, summaryFile);
    } else {
      fileData = await this.generateUnalignedContent(summaryFile);
    }

    await fileDownload(fileData, this.filename);
  }

  async initialiseData(clearDB) {
    this.db = new IndexedDB(null, this.filename);
    if (clearDB) await this.db.clear();
  }
}
