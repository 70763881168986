import React from 'react';
import './Footer.scss';
import { Container, Col, Row } from 'Components';
import { Link } from 'react-router-dom';
import GlobalConfig from 'Config';

const Footer = () => {
  return (
    <Container fluid className="px-0 mt-6">
      <Row>
        <Col>
          <footer className="footer">
            <div className="footer__text">
              © All Rights Reserved by ONT | <Link to="/change-log">v{GlobalConfig.version} - view change log</Link> | <Link to="/benchmarks">Benchmark</Link>
            </div>
          </footer>
        </Col>
      </Row>
    </Container>
  );
};
export default Footer;
