import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { folder } from 'Images';
import { i18n, getFileMetadata, fileChecker } from 'Utils';
import classNames from 'classnames';
const pako = require('pako');

import './Dropzone.scss';

const Dropzone = ({ onDropped }) => {
  const accept = ['.fast5', '.gz'];

  const handleGzip = (data) => {
    let dataInt8Arr = new Uint8Array(data);
    if(fileChecker.checkIfGZip(dataInt8Arr)) {
      // Decompress
      return pako.inflate(dataInt8Arr).buffer;
    }
    return data;
  }

  const onDrop = useCallback((acceptedFiles) => {
    const droppedFiles = [];
    acceptedFiles.forEach((file) => {
      const filePromise = new Promise((rs, rj) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = function (e) {
          const fileData = handleGzip(e.target.result);
          rs({
            name: file.name,
            size: file.size,
            data: fileData
          })

        };
        fileReader.onerror = () => rj(fileReader.error);
        return file;
      });
      droppedFiles.push(filePromise)
    });

    Promise.all(droppedFiles).then((files) => {
      onDropped(getFileMetadata(files))
    })
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: true,
  });

  const dropzoneClasses = classNames({
    'dropzone': true,
    'dropzone-active': isDragActive,
    'p-5': true,
  });

  let dropText = isDragActive ? "Release to drop the files here" : "Drag and drop to add .fast5 files";

  return (
    <div className={dropzoneClasses} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        <img src={ folder } className="mb-2" />
        <p className="dropzone-content">
          { i18n(dropText) } 
          {!isDragActive && <span className="mt-1">{ i18n("or click to browse") }</span>}
        </p>
      </div>
    </div>
  );
};

export default Dropzone;
