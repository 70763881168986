import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Utils';

import './ActionButton.scss';

const ActionButton = ({ classname, onClick, text, icon, disabled, testId }) => (
  <button className={`actionbutton ${classname}`} onClick={onClick} disabled={disabled} data-testid={testId}>
    {i18n(text)}
    {icon && <img src={icon} className="ms-2" />}
  </button>
);

ActionButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default ActionButton;
