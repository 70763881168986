import React from 'react';
import './Page.scss';
import { Header, Container, Row, Footer } from 'Components';

const pageWrapper = (Component) => {
  return (
    <div className="page">
      <Container fluid>
        <Row>
          <Header />
          <main className="px-0">
            <Component />
          </main>
          <Footer />
        </Row>
      </Container>
    </div>
  );
};

export default pageWrapper;
