import { MultiFast5 } from 'Services/file';

export const sendTrackingData = (events) => {
  if (window.heap) {
    events.forEach((element) => {
      window.heap.track(element.name, { ...element.data });
    });
  }
};

export const getFileMetadata = (fileList) => {
  return fileList.map((file) => {
    let count = 0;
    let sequenceDate = null;
    const mf5 = new MultiFast5(file.data, file.name);
    for (let r of mf5.reads()) {
      //eslint-disable-line
      count++;
      if (!sequenceDate) {
        sequenceDate = r.exp_start_time;
      }
    }
    return { ...file, count, sequenceDate };
  });
};

const gzipValues = [0x1f, 0x8b, 0x8];

export const fileChecker = {
  checkIfGZip: (fileBuffer) => {
    return fileChecker.checkFileType(fileBuffer, gzipValues);
  },
  checkFileType: (buffer, headers) => {
    for (const [index, header] of headers.entries()) {
      if (header !== buffer[index]) {
        return false;
      }
    }
    return true;
  },
};

export * from './pageHelper';
