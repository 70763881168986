export default {
  inference: {
    inferenceStatus: {
      steps: [],
      error: false,
      vbzError: false,
      gzipError: false,
      badBatchError: false,
      percent: 0,
      active: false,
      totalReads: 0,
      status: 'preparing'
    },
  },
  device: {
    deviceInfo: {},
  },
  ui: {
    library: 'Tensorflow',
    backend: 'webgpu',
    inferenceStarted: false,
    model: {},
    fileExtension: { value: '.bam', name: 'BAM' },
  },
};
