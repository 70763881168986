import React, { useState } from 'react';
import classNames from 'classnames';
import { i18n } from 'Utils';
import { buttonArrow } from 'Images';

import './ProgressList.scss';

const ProgressList = ({ steps = [] }) => {
  const [showAll, toggleShowAll] = useState(false);

  const batchClasses = classNames({
    'batch-steps': true,
    'batch-steps__show-all': showAll,
    'ps-0': true,
  });

  return (
    <div className={batchClasses}>
      <span className="batch-steps__see-all font-700" onClick={() => toggleShowAll((s) => !s)}>
        <img src={buttonArrow} className="me-2 see-all-icon" /> {i18n('See all')}
      </span>

      {showAll && (
        <ul className="mt-3 batch-steps__list">
          {steps.length > 0 &&
            steps.map((step, i) => (
              <li key={`step-${i}`} className={step.major ? 'batch-steps__item_success' : ''}>
                {step.message}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default ProgressList;
