import React, { useState, useEffect } from 'react';
import { Container, Row, Col, StaticGPUDetection, ModelSelect, ActionButton, DevTools } from 'Components';
import { i18n } from 'Utils';
import { runBenchmark, getAppStatus, loadBasecaller } from 'Actions';
import { connect } from 'react-redux';
import './Benchmarks.scss';
import { sendTrackingData } from 'Utils';
import GlobalConfig from 'Config';

const Benchmarks = ({ appStatus, loadBasecaller, runBenchmark }) => {
  const [data, setData] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  let { backend, library, model } = appStatus;
  const isModelSet = Object.keys(model).length > 0;
  const disableStart = !isModelSet;
  const { tensorSize, benchmarkDataAmount } = GlobalConfig.mlconfig;
  const [batchConfig, setBatchConfig] = useState({ tensorSize, dataAmount: benchmarkDataAmount });

  const startBenchmark = async () => {
    setData([]);
    if (disableStart) return;
    setIsRunning(true);
    const benchmarkData = await runBenchmark(batchConfig.tensorSize, batchConfig.dataAmount);
    setData(benchmarkData);
    setIsRunning(false);
  };
  useEffect(() => {
    if (data.length > 0) {
      sendTrackingData([{ name: 'Benchmarking', data: { batchData: data, backend } }]);
    }
  }, [data]);

  useEffect(async () => {
    if (Object.keys(model).length > 0) {
      await loadBasecaller(library, model, backend);
    }
  }, [model]);

  return (
    <div className="benchmarkpage my-6">
      <Container>
        <Row>
          <DevTools batchConfig={batchConfig} setBatchConfig={setBatchConfig} />
          <div className="benchmarkpage__header my-5">
            <h1>{i18n('Benchmark Page')}</h1>
            <StaticGPUDetection />
          </div>
        </Row>

        <Row>
          <div className="benchmarkpage__select my-6">
            <h5>
              <p>{i18n('Select your Model:')}</p>
            </h5>
            <div className="benchmarkpage__model">
              <div className="modelselect">
                <ModelSelect showDetails={false} md="10" />
              </div>

              <div>
                <ActionButton
                  md="2"
                  classname={disableStart || isRunning ? 'disabled-button' : 'proceed-button'}
                  onClick={isRunning ? () => {} : startBenchmark}
                  text={isRunning ? i18n('Running benchmark...') : i18n('Start')}
                  disabled={disableStart || isRunning}
                  testId="btn-start-benchmark"
                />
              </div>
            </div>
          </div>
        </Row>

        <p>
          Disclaimer: Please be aware that the benchmark is solely running the model and not the pre and post processing
          for the model.
        </p>

        <Row className="benchmarkpage__columntitle font-700">
          <Col md="2">
            <span>{i18n('Batch Size')} </span>
          </Col>
          <Col md="1">
            <span>{i18n('Inference')} </span>
          </Col>
          <Col md="3">
            <span>{i18n('Time (seconds)')} </span>
          </Col>
          <Col md="3">
            <span>{i18n('Samples')} </span>
          </Col>
          <Col md="3">
            <span>{i18n('Samples/s')} </span>
          </Col>
        </Row>
        {data.map(({ batchSize, timeTaken, samples, samplesPerS, inference }, i) => (
          <Row className="benchmarkpage__column" key={i}>
            <Col md="2" className="">
              <span>{batchSize}</span>
            </Col>
            <Col md="1" className="">
              <span>{inference}</span>
            </Col>
            <Col md="3">
              <span>{timeTaken.toFixed(2)}</span>
            </Col>
            <Col md="3">
              <span>{samples}</span>
            </Col>
            <Col md="3">
              <span>{samplesPerS.toExponential(2)}</span>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appStatus: getAppStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runBenchmark: (tensorSize, dataAmount) => dispatch(runBenchmark(tensorSize, dataAmount)),
    loadBasecaller: (library, model, backend) => dispatch(loadBasecaller(library, model, backend, false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Benchmarks);
