import React from 'react';
import Card from '../../molecules/Card';
import './HowItWorks.scss';
import { arrow } from 'Images';

const cardInformation = [
  {
    title: 'Add Files',
    description: 'Drag and drop a .fast5 file into this tab to get started.',
  },
  {
    title: 'Configure',
    description: 'Set your basecalling model and where you want the reads to be downloaded.',
  },
  {
    title: 'Receive',
    description: 'See real-time inferences and download a .sam or .bam file at the end.',
  },
];

const HowItWorks = () => {
  return (
    <>
      <div className="hiw-wrapper mt-6 mb-6">
        <div className="hiw-wrapper__header">
          <h4 className="hiw-wrapper__title mb-5">How It Works</h4>
        </div>
        <div className="hiw-container d-flex">
          {cardInformation.map((el, index) => {
            return (
              <React.Fragment key={el.title}>
                <Card position={index + 1} title={el.title} description={el.description} />
                {index < 2 && <img src={arrow} alt="arrow" className="arrow" />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default React.memo(HowItWorks);
