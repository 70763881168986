import React from 'react';
import classNames from 'classnames';
import { downloadedIcon, toDownloadRed } from 'Images';
import './CustomOption.scss';

const CustomOption = ({ innerProps, isSelected, data, selectProps }) => {
  const { offlineModels } = selectProps;

  const listClasses = classNames({
    'custom-option': true,
    'custom-option__selected': isSelected,
  });

  let icon;
  if (offlineModels){
    icon = !offlineModels.includes(data.name) ? toDownloadRed : downloadedIcon;
  }

  return (
    <div {...innerProps} className={listClasses}>
      {offlineModels && <img src={icon} width="16px" height="16px" />}
      {data.name}
    </div>
  );
};

export default CustomOption;
