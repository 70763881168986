import React from 'react';
import classNames from 'classnames';

import { Progress } from 'Components';

import './ProgressBar.scss';

const ProgressBar = ({ error, percent, stopped }) => {
  const progessClasses = classNames({
    'progress-bar__multi__success': !stopped,
    'progress-bar__multi__stopped': stopped,
    'progress-bar__multi__error': error,
  });

  return (
    <div className="progress-bar progress-completed">
      <Progress multi className="progress-bar__multi">
        <Progress bar className={progessClasses} value={percent}>
          {percent}%
        </Progress>
      </Progress>
    </div>
  );
};

export default ProgressBar;
