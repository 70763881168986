import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { i18n } from 'Utils';
import { ActionButton } from 'Components';

import './ActionModal.scss';

const ActionModal = ({ actionButton, body, closeModal, onClick, title }) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      className="modal-container action-modal"
      overlayClassName="modal-overlay"
      appElement={document.querySelector('#root')}
    >
      <p className="font-bold">{i18n(title)}</p>
      <p>{i18n(body)}</p>
      <div className="text-center">
        <ActionButton classname="cancel-button" onClick={closeModal} text={i18n('Cancel')} />
        <ActionButton classname="action-button" onClick={() => onClick(closeModal)} text={i18n(actionButton)} />
      </div>
    </Modal>
  );
};

ActionModal.propTypes = {
  actionButton: PropTypes.string,
  body: PropTypes.string,
  closeModal: PropTypes.func,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default ActionModal;
