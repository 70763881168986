import GlobalConfig from 'Config';

export const logger = {
  log: (name, ...props) => {
    const text = GlobalConfig.loggerMsgs(name, ...props);
    GlobalConfig.showLogs && console.log(text);
    return text;
  },
  error: (name, props) => {
    const errorText = GlobalConfig.loggerMsgs(name, props);
    GlobalConfig.showLogs && console.error(errorText);
    return Error(errorText);
  },
  dump: (name, props) => {
    const desc = GlobalConfig.loggerMsgs(name);
    GlobalConfig.showLogs && console.log(desc, props);
  },
};
