import React from 'react';
import { Row, Col } from 'Components';

import './ProcessRow.scss';

const ProcessRow = ({ title, renderItem }) => {
  return (
    <div className="p-3 process-row">
      <Row>
        <Col md="3">
          <p className="mb-0 process-row__title">{title}</p>
        </Col>
        <Col md="9">{renderItem()}</Col>
      </Row>
    </div>
  );
};

export default ProcessRow;
