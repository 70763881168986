import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Utils';
import { circleTick } from 'Images';

const ModelSelectionTitle = ({ flowcell, number, section, onClick, kit }) => {
  const isFlowcellOrKit = ['flowcell', 'kit'].includes(section);
  return (
    <div
      className={`model-model__section selected-section mb-5 ${isFlowcellOrKit ? 'disabled' : ''}`}
      onClick={onClick}
    >
      <h4 className="model-modal__number mb-0">{number}</h4>
      <h5 className="mb-0 model-modal__selected_model pe-6">
        {isFlowcellOrKit && <span className="me-3">{i18n('Available basecalling algorithms')}</span>}
        {section === 'algorithm' && (
          <>
            {i18n('Flow cell: ')}
            <span className="me-3">{flowcell}</span>
            {i18n('Kit: ')}
            <span>{kit}</span>
            <img src={circleTick} />
          </>
        )}
      </h5>
    </div>
  );
};

ModelSelectionTitle.propTypes = {
  section: PropTypes.string,
  flowcell: PropTypes.string,
  substrate: PropTypes.string,
  number: PropTypes.number,
  onClick: PropTypes.func,
};

export default ModelSelectionTitle;
