import React from 'react';
import PropTypes from 'prop-types';
import { ActionModal } from 'Components';

const RemoveFilesModal = ({ itemToRemove, closeModal, removeAction }) => {
  const handleRemove = (closeModal) => {
    removeAction(itemToRemove);
    closeModal();
  };

  let title = 'Are you sure you want to remove all files?';
  let body = 'This will reset the application. All files will stay on your machine.';
  let removeButton = 'Remove all files';

  if (itemToRemove !== null) {
    title = 'Are you sure you want to remove this file?';
    body = "The file will stay on your machine but won't be included in this basecalling run";
    removeButton = 'Remove file';
  }

  return (
    <ActionModal actionButton={removeButton} body={body} closeModal={closeModal} onClick={handleRemove} title={title} />
  );
};

RemoveFilesModal.propTypes = {
  itemToRemove: PropTypes.number,
  closeModal: PropTypes.func,
  removeAction: PropTypes.func,
};

export default RemoveFilesModal;
