import { SamFile, OutputHandler, BamFile } from '../file';
import { readTagsKey, samExtension } from '../file/constants';
import Profiler from '../profiler';
import GlobalConfig from 'Config';
import { PromisePool } from '@supercharge/promise-pool';
import { logger } from 'Utils/logger';

export default class Batch {
  constructor(basecaller, config, updateProgress, currentProgress) {
    this.config = config;
    this.basecaller = basecaller;
    this.updateProgress = updateProgress;
    this.currentProgress = currentProgress;
    this.totalReads = 0;
    this.samples = 0;
    this.fileCount = 0;
    this.reads = [];
    this.batches = [];
    this.readsPosition = 0;
  }

  totalFileReads(fileList) {
    return fileList.reduce((totalCount, file) => {
      return totalCount + file.count;
    }, 0);
  }

  addReadMetadata(fileName, reads, outputFile) {
    const readGroups = [];
    const readTags = [];
    reads.forEach((read) => {
      this.buildReadGroups(readGroups, read.readGroup);
      const tags = this.getReadTags(read, read.readGroup, fileName);
      readTags.push(tags);
    });
    outputFile.bulkAddReadTags(readTags);
    outputFile.addReadGroupsData(readGroups);

    return reads;
  }

  async executeFiles(fileList, referenceFile) {
    const startTime = performance.now();
    this.outputHandler = new OutputHandler(referenceFile);
    await this.outputHandler.clearEntries();
    this.fileCount = fileList.length;
    this.totalReads = this.config.maxReads == 0 ? this.totalFileReads(fileList) : this.config.maxReads * this.fileCount;

    for (const file of fileList) {
      this.updateProgress({ message: `File - ${file.name}` });
      await this.executeFile(file);
    }

    await this.outputHandler.appendProfileData(Profiler.parse());
    if (this.isActive()) this.updateProgress({ percent: 100, status: 'completed', totalSamples: this.samples });
    this.basecaller.terminateWorkers();
    const endTime = performance.now();
    logger.log('readsFinished', (endTime - startTime) / 1000, this.samples, this.totalReads);
    return this.outputHandler;
  }

  async executeFile(file) {
    if (this.config.useNativeProfiler) {
      let outputFile;
      let profileData = await this.basecaller.session.startProfiling(async () => {
        outputFile = await this.executeProcess(file);
      });
      await outputFile.appendProfileData(profileData);
      return outputFile;
    }
    return await this.executeProcess(file);
  }

  async executeProcess(file) {
    const { fileExtension } = this.config;
    const outputFile = fileExtension.value === samExtension ? new SamFile(file.name) : new BamFile(file.name);
    this.outputHandler.addFile(outputFile);
    await this.processReads(file, outputFile);
    return outputFile; // TODO: Update this return method
  }

  /**
   * Process File Reads
   * @param {array} fileData The raw data from the file
   * @param {string} fileName Name of the file
   * @param {object} outputFile The SAM or BAM file handler
   * @returns Inference scores from the model
   */
  async processReads(file, outputFile) {
    const { name: fileName, data: fileData } = await this.basecaller.loadFile(file);

    this.batches = [];
    this.reads = this.addReadMetadata(fileName, fileData, outputFile);

    let { modelStride, features } = this.config;
    const { percent } = this.currentProgress();
    const decodePromises = [];
    const workerAmount = GlobalConfig.services.workers.inference;
    const batchPreloadAmount = workerAmount * 2;

    if (percent === 0) this.updateProgress({ status: 'preloadingReads' });
    await this.preloadBatch(batchPreloadAmount);

    if (percent === 0)
      this.updateProgress({
        percent: 1,
        totalReads: this.totalReads,
        active: true,
        status: 'active',
        timestamp: new Date().valueOf(),
      });
    Profiler.startEvent({ id: 'processReads', name: 'Process Reads' });

    //Start batch inference based on batches
    while (this.batches.length || this.reads.length) {
      const batches = this.batches.splice(0, batchPreloadAmount);

      this.preloadBatch(batchPreloadAmount);

      if (batches.length === 0) {
        await this.waitForBatch();
      }

      await PromisePool.withConcurrency(workerAmount)
        .for(batches)
        .process(async (batch, i, pool) => {
          if (!this.isActive()) return pool.stop();

          const { batchMetaData, batchIdx } = await this.basecaller.createAndRunTensors(batch);

          const decodeBatchPromise = this.basecaller
            .decodeBatch(batchMetaData, outputFile.filename, modelStride, features)
            .then(() => {
              // Set UI Percentage
              const completedReads = batchMetaData.filter((r) => r.isEndOfRead);
              this.readsPosition += completedReads.length;
              const percent = Math.round((this.readsPosition / this.totalReads) * 100);

              this.updateProgress({ percent: percent < 100 ? percent : 99 }); // 99% cap when all the reads complete; and 1% after parallel processing done.
              this.updateProgress({ message: `Batch ${batchIdx}`, major: true });
            });

          decodePromises.push(decodeBatchPromise);
        });
    }

    await Promise.all(decodePromises);

    Profiler.endEvent({ id: 'processReads', name: 'Process Reads' });
  }

  async preloadBatch(amount) {
    const { batches, reads, samples } = await this.basecaller.preloadBatch(
      amount,
      this.reads,
      this.config,
      this.batches,
    );
    this.batches = batches;
    this.reads = reads;
    this.samples = samples;
  }

  async waitForBatch() {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.batches.length) {
          resolve();
          clearInterval(interval);
        }
      }, 50);
    });
  }

  buildReadGroups(readGroups, readGroup) {
    const { run_id, exp_start_time, flow_cell_id, device_id, sample_id } = readGroup;

    if (!readGroups.some((group) => group.run_id === run_id)) {
      readGroups.push({
        run_id,
        exp_start_time,
        flow_cell_id,
        device_id,
        sample_id,
        model: this.basecaller.model.name,
      });
    }
  }

  getReadTags(read, readGroup, filename) {
    const { shift, scale } = read;
    const { start_mux, start_time, read_number, duration, read_id } = read.raw;
    const { channel_number, sampling_rate } = read.channel_id;
    const { run_id, exp_start_time } = readGroup;

    const readTags = {
      run_id,
      model: this.basecaller.model.name,
      mux: start_mux,
      channel: channel_number,
      start: start_time / sampling_rate,
      exp_start_time,
      read_number,
      filename,
      numSamples: duration,
      shift,
      scale,
      id: `${read_id}_${readTagsKey}`,
    };

    return readTags;
  }

  isActive() {
    const { active } = this.currentProgress();
    return active;
  }
}
