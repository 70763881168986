
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import History from './history';
import { Dashboard, WebGPU, ChangeLog, PageWrapper, Benchmarks } from './pages';

const PageRouter = () => (
  <Router history={History} basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route exact path="/" element={PageWrapper(Dashboard)} />
      <Route path="*" element={PageWrapper(Dashboard)} />
      <Route exact path="/about-webgpu" element={PageWrapper(WebGPU)} />
      <Route exact path="/change-log" element={PageWrapper(ChangeLog)} />
      <Route exact path="/benchmarks" element={PageWrapper(Benchmarks)} />
    </Routes>
  </Router>
);

export default PageRouter;
