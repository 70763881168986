import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setConfigOptions, loadDefaultSettings } from 'Actions';
import { ProcessTitle, ProcessRow, ProcessList, ActionButton, ModelSelect, ReferenceFile, FileTypeSelect } from 'Components';
import { play } from 'Images';

import './ProcessDetails.scss';
import { formatBytes, i18n } from 'Utils';

const calculateTotalFileSize = (files) => files.reduce((acc, curr) => acc + curr.size, 0);

const ProcessDetails = ({
  files,
  setConfigOptions,
  setDroppedFiles,
  disableStart,
  loadDefaultSettings,
  setReferenceFile,
}) => {
  const memoizedTotalsize = React.useMemo(() => formatBytes(calculateTotalFileSize(files)), [files]);
  const startInference = () => setConfigOptions({ inferenceStarted: true });

  const referenceFileOnChange = (event) => setReferenceFile(event.target.files[0]);

  useEffect(async () => {
    loadDefaultSettings();
  }, []);

  return (
    <div className="process-details">
      <ProcessTitle
        files={files}
        renderItem={() => (
          <ActionButton
            classname={disableStart ? 'disabled-button' : 'proceed-button'}
            onClick={startInference}
            text={i18n('Start')}
            icon={play}
            disabled={disableStart}
          />
        )}
      />

      <ProcessRow
        title={
          <span>
            {i18n('Files added')}
            <span className="font-italic font-400 d-block">
              ({files.length} {i18n('discovered')})
            </span>
          </span>
        }
        renderItem={() => <ProcessList files={files} setDroppedFiles={setDroppedFiles} />}
      />

      <ProcessRow title={i18n('Total file size')} renderItem={() => memoizedTotalsize} />

      <ProcessRow title={i18n('Model Used')} renderItem={() => <ModelSelect />} />

      <ProcessRow title={i18n('Output Type')} renderItem={() => <FileTypeSelect />} />

      <ProcessRow
        title={i18n('Reference file')}
        renderItem={() => <ReferenceFile onChange={referenceFileOnChange} />}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfigOptions: (options) => dispatch(setConfigOptions(options)),
    loadDefaultSettings: () => dispatch(loadDefaultSettings()),
  };
};

export default connect(null, mapDispatchToProps)(ProcessDetails);

ProcessDetails.propTypes = {
  files: PropTypes.array,
  setConfigOptions: PropTypes.func,
  setDroppedFiles: PropTypes.func,
  disableStart: PropTypes.bool,
  loadDefaultSettings: PropTypes.func,
};
