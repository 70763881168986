import React from 'react';
import './Card.scss';
import { i18n } from 'Utils';

const Card = ({ position, title, description }) => {
  return (
    <div className="cards">
      <div className="cards__body">
        <h5 className="cards__number">{i18n(position)}</h5>
        <h5 className="cards__title">{i18n(title)}</h5>
        <p className="cards__description mb-0">{i18n(description)}</p>
      </div>
    </div>
  );
};

export default Card;
