import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './DevTools.scss';

const DevTools = ({ batchConfig, setBatchConfig, autoSelectBatchSize }) => {
  const isDev = useLocation().search.indexOf('?devtools') > -1;
  const [closed, setClosed] = useState(false);

  if (!isDev) return false;

  const updateBatchConfig = (name, val) => setBatchConfig(prev => ({ ...prev, [name]:val }))

  return (
    <div className={`devtools ${closed ? 'is-closed' : ''}`}>
      <div className="devtools_close-container">
        <div className="devtools_title">Dev Tools</div>
        <div className="devtools_close-btn" onClick={() => setClosed(!closed)}>{closed ? '◄' : 'X'}</div>
      </div>
      {!closed && (
        <>
          {
            Object.keys(batchConfig).map((key, i) => (
              <div key={i + key}>
                <label htmlFor={key}>{key} {key === 'maxReads' ? '(0 = process all reads)' : ''}</label>
                <input
                  className="prop-input"
                  id={key}
                  name={key}
                  type="text"
                  value={batchConfig[key]}
                  onChange={(e) => {
                    const val = parseInt(e.target.value);
                    updateBatchConfig(key, val || 0);
                  }}
                />
              </div>
            ))
          }
          { autoSelectBatchSize && (<button onClick={() => autoSelectBatchSize()}>Rerun Batch Size Selector</button>) }
         
        </>
      )}
    </div>
  );
};
export default DevTools;
