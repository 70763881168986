import './ErrorModal.scss';

import { ActionButton } from 'Components';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Utils';

const ErrorModal = ({ closeModal, onClick, vbzError, gzipError, badBatchError }) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      className="modal-container error-modal"
      overlayClassName="modal-overlay"
      appElement={document.querySelector('#root')}
    >
      <p className="font-bold">{i18n('Basecalling run has been interrupted')}</p>

      {vbzError && <p>{i18n('VBZ compressed .fast5 files are not currently supported, but will be soon.')}</p>}
      {gzipError && <p>{i18n("Currently gz compressed fast5 files with a .fast5 extension aren't supported, but will be soon.")}</p>}
      {badBatchError && <p>{i18n('One of your uploaded files is corrupted. Please ensure all files are a valid .fast5 file.')}</p>}

      {!vbzError && !gzipError && !badBatchError && (
        <>
          <p>{i18n('Something has interrupted basecalling. This can happen because:')}</p>
          <ul>
            <li>{i18n('Your machine has run out of battery life')}</li>
            <li>{i18n('Your machine has been disconnected from power')}</li>
            <li>{i18n('Your machine has run out of storage space')}</li>
          </ul>
          <p>{i18n('You can try to continue from the last saved position, or stop the run.')}</p>

          <div className="text-center">
            <ActionButton classname="cancel-button" onClick={() => onClick(closeModal)} text={i18n('Stop run')} />
          </div>
        </>
      )}
    </Modal>
  );
};

ErrorModal.propTypes = {
  closeModal: PropTypes.func,
  onClick: PropTypes.func,
  vbzError: PropTypes.bool,
  gzipError: PropTypes.bool,
  badBatchError: PropTypes.bool,
};

export default ErrorModal;
