import { Container, LogBox, Row, WebGPUButton } from 'Components';

import React from 'react';
import { gpuArrow } from 'Images';
import { i18n } from 'Utils';

const ChangeLog = () => {
  return (
    <div className="changelogpage my-6">
      <Container>
        <div>
          <WebGPUButton image={gpuArrow} text="Return to Basecaller" />
        </div>
        <Row>
          <h1>{i18n('Change Log')}</h1>
          <p>{i18n('All notable changes to this project will be documented on this page.')}</p>
        </Row>
      </Container>
      <Container>
        <Row>
          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.6')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.6.')} </p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('Added new models:')}</li>
              <ul>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_fast@v4.2.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_hac@v4.2.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_fast@v4.1.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_hac@v4.1.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_260bps_fast@v4.1.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_260bps_hac@v4.1.0')}</li>
              </ul>
              <li className="logbox__text">{i18n('Reordered model select list')}</li>
            </ul>
          </LogBox>
          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.5')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.5.')} </p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('Added new models:')}</li>
              <ul>
                <li>{i18n('dna_r10.4.1_e8.2_260bps_fast@v4.0.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_260bps_hac@v4.0.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_fast@v4.0.0')}</li>
                <li>{i18n('dna_r10.4.1_e8.2_400bps_hac@v4.0.0')}</li>
              </ul>
              <li className="logbox__text">{i18n('Minor bug fixes')}</li>
            </ul>
          </LogBox>
          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.4')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.4.')} </p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('Added Fast and High Accuracy models (r9 & r10)')}</li>
              <li className="logbox__text">{i18n('Added Summary file')}</li>
              <li className="logbox__text">{i18n('Performance and memory management improvements')}</li>
              <li className="logbox__text">{i18n('Switch zero padding to repeat padding for short reads')}</li>
              <li className="logbox__text">{i18n('Updated SAM file readtags')}</li>
              <li className="logbox__text">{i18n('Minor bug fixes')}</li>
            </ul>
          </LogBox>
          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.3')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.3.')} </p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('Support for SAM files')}</li>
              <li className="logbox__text">{i18n('Support for BAM Files')}</li>
              <li className="logbox__text">{i18n('Benchmark Page')}</li>
            </ul>
          </LogBox>

          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.2')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.2.')} </p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('VBZ Compression')}</li>
            </ul>
          </LogBox>

          <LogBox>
            <h2 className="logbox__title">{i18n('Version 0.1')}</h2>
            <p className="logbox__description">{i18n('Here are some of the updates for version 0.1.')}</p>
            <ul className="logbox__changes">
              <li className="logbox__text">{i18n('GPU Detection')}</li>
              <li className="logbox__text">{i18n('Device Name for GPU')}</li>
              <li className="logbox__text">{i18n('Performance Improvements - Beam Search')}</li>
              <li className="logbox__text">{i18n('Web Workers')} </li>
              <li className="logbox__text">
                {i18n('Detailed model selection including flow cell and basecalling algorithm options')}
              </li>
              <li className="logbox__text">{i18n('Ability to keep track of local and offline model status')}</li>
            </ul>
          </LogBox>
        </Row>
      </Container>
    </div>
  );
};

export default ChangeLog;
