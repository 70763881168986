import './EnableWebGPU.scss';

import { chromeLogo, edgeLogo, firefoxLogo } from 'Images';

import BrowserCard from '../../molecules/BrowserCard';
import React from 'react';
import { i18n } from 'Utils';

const EnableWebGPU = () => {
  const browserInformation = [
    {
      image: chromeLogo,
      title: 'Google Chrome',
      pointOne: 'Already enabled in latest version!',
    },
    {
      image: edgeLogo,
      title: 'Edge',
      pointOne: 'Already enabled in latest version!',
    },
    {
      image: firefoxLogo,
      title: 'Firefox',
      pointOne: "1. Ensure you're on the latest version of Firefox (v73 or newer)",
      pointTwo: '2. Navigate to <code> about:config </code>',
      pointThree: ' 3. Enable <code>dom.webgpu.enabled</code>',
    },
    {
      classname: 'otherbrowsers',
      title: 'Other Browsers',
      pointOne: 'This page will be updated when WebGPU can be enabled on the latest versions of other browsers.',
    },
  ];

  return (
    <div className="enablewebgpu my-6" id="enablegpu">
      <h4 className="enablewebgpu__header">{i18n('How to enable WebGPU today')}</h4>
      <p className="enablewebgpu__description">{i18n('The instructions below are for desktop browsers only.')}</p>
      <div className="enablewebgpu__browsers row-12">
        {browserInformation.map((el) => {
          return (
            <React.Fragment key={el.title}>
              <BrowserCard
                classname={el.classname}
                image={el.image}
                title={el.title}
                pointOne={el.pointOne}
                pointTwo={el.pointTwo}
                pointThree={el.pointThree}
                pointFour={el.pointFour}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(EnableWebGPU);
