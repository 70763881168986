const envType = process.env.ENV;
const getStarted = (bool) => bool ? "STARTED" : "FINISHED";

export default {
  env: envType,
  isDev: envType === 'dev',
  loggerMsgs: (name, ...props) => {
    const msgs = {
      mlconfig: () => `ML Configuration::`,
      mlerror: (message) => `ML Error::${message}`,
      modelLoaded: (url) => `Model loaded:: ${url}`,
      backendLoaded: (backend) => `Backend loaded:: ${backend}`,
      inferenceComplete: () => `Inference Complete:: Processing output...`,
      noModelUrl: () => 'No URL for Model:: Please define a URL for tensorflow to load',
      badTensorShape: () => 'Bad Tensor Shape:: Tensor has missing or incorrect values',
      libraryNotFound: (libName) => `Cannot find library:: ${libName}`,
      operationNotFound: (name) => `Cannot find operation:: ${name}`,
      processRead: (readId, count) => `Reading:: ${readId} - Read No.:: ${count}`,
      readsFinished: (time, samples, readAmt) => `Time Taken (S):: ${time} - Samples Processed:: ${samples} - Read Amount:: ${readAmt}`,
      skipRead: (readSize, tensorSize) => `Skipping read of length ${readSize}, less than minimum TensorSize ${tensorSize} -- will be implemented soon`,
    };
    return msgs[name](...props);
  },
  profilerMsgs: (name, ...props) => {
    const msgs = {
      custom: (customMsg) => `${customMsg}`,
      mainLoop: () => `Reads Main Loop`,
      startRead: (readId, sampleSize, started = true) => `${getStarted(started)} - Read ${readId} - Samples:${sampleSize}`,
      runBatch: (readId) => `Run Batch - ReadID: ${readId}`,
      calcMaxSize: () => `Calculated MaxSize`,
      sliceNormSignal: (endStitch = false, started = true) => `${getStarted(started)} ${endStitch ? "- (endStitch)" : ""} - Slicing Norm Signal`,
      runAndProcessSessionOuter: (endStitch = false, started = true) => `${getStarted(started)} ${endStitch ? "- (endStitch)" : ""} - Running And Process Session`,
      getRawData: (started = true) => `${getStarted(started)} - Getting Raw Data`,
      stitchAndMapData: (started = true) => `$${getStarted(started)} - Stitching and Mapping Data`,
      addSequenceData: (started = true) => `${getStarted(started)} - Adding Sequence Data`,
      runAndProcessSessionInner: (readId, index) => `RunAndProcess Session - ReadID: ${readId}, Index:${index}`,
      createAndRunTensor: (started = true) => `${getStarted(started)} - Create and run tensor`,
      addRawData: (started = true) => `${getStarted(started)} - Adding Raw Data`,
    };
    return msgs[name](...props);
  }
};
