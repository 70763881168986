import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonLink } from 'Components';
import classNames from 'classnames';
import { i18n } from 'Utils';
import { deviceInfo, getGPUDetectedStatus, getDeviceRenderer } from 'Actions';
import { microchipRed, microchipGreen } from 'Images';
import { bindActionCreators } from 'redux';

import store from '../../../redux/store';

import './GPUDetection.scss';

const GPUDetection = ({ gpuDetected, deviceName }) => {
  useEffect(() => {
    actions.deviceInfo();
  }, []);

  const gpuClass = classNames({
    'gpuDetection': true,
    'p-5': true,
    'active': gpuDetected,
  });

  let title = 'GPU not detected';
  let mainContent = 'We recommend using a GPU for the best performance when basecalling.';
  let icon = microchipRed;
  let buttonStyle = 'danger';

  if (gpuDetected) {
    title = 'GPU detected';
    mainContent = 'We expect your machine to perform well when basecalling';
    icon = microchipGreen;
    buttonStyle = 'success';
  }

  return (
    <div className={gpuClass}>
      <img src={icon} className="mb-4" />
      <h5>{i18n(title)}</h5>
      <p>
        <span>{i18n(deviceName)}</span>
      </p>
      <p className="mr-4">{i18n(mainContent)}</p>
      <ButtonLink to="/about-webgpu" text={i18n('Find out more')} buttonStyle={buttonStyle} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gpuDetected: getGPUDetectedStatus(state),
    deviceName: getDeviceRenderer(state),
  };
};

export default connect(mapStateToProps)(GPUDetection);

const actions = bindActionCreators({ deviceInfo }, store.dispatch);

GPUDetection.propTypes = {
  gpuDetected: PropTypes.bool,
};
