import './ModelSelect.scss';

import { ActionButton, CustomOption, ModelModal } from 'Components';
import React, { useEffect, useState } from 'react';
import { getLibrary, getModel, saveDefaultModel, setConfigOptions } from 'Actions';

import GlobalConfig from 'Config';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Storage } from 'Services';
import { connect } from 'react-redux';
import { i18n } from 'Utils';

const ModelSelect = ({ library, model, setConfigOptions, showDetails }) => {
  const [offlifeModels, setOfflifeModels] = useState([]);
  const [showModal, toggleModal] = useState(false);
  const [defaultModel, setDefaultModel] = useState(false);
 
  useEffect(async () => {
    const indexedDb = await new Storage['IndexedDB'](library);
    const models = await indexedDb.getModelKeys();
    setOfflifeModels(models);
  }, [library]);

  useEffect(() => {
    if (defaultModel && Object.keys(model).length > 0) {
      saveDefaultModel(model.name);
    }
  }, [defaultModel, model]);

  const setModel = (model) => setConfigOptions({ model });

  const closeModal = () => toggleModal(false);

  const libraryModels = GlobalConfig.services.libraries.paths[library];

  return (
    <div className="model-select-wrapper">
      {showModal && (
        <ModelModal
          closeModal={closeModal}
          availableModels={libraryModels}
          setModel={setModel}
          defaultModel={defaultModel}
          setDefaultModel={setDefaultModel}
        />
      )}

      <div className="model-select-wrapper__inputs">
        <div className="model-select-wrapper__dropdown" data-testid="model-select">
          <Select
            classNamePrefix="model-select"
            value={model}
            onChange={setModel}
            options={libraryModels}
            components={{ Option: CustomOption }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option}
            offlineModels={offlifeModels}
          />
          {showDetails && (
            <label htmlFor="default-future-modal" className="mt-2">
              {i18n('Use this model by default in the future')}
              <input
                className="ms-2"
                type="checkbox"
                name="default-future-modal"
                checked={defaultModel}
                onChange={() => setDefaultModel((s) => !s)}
              />
            </label>
          )}
        </div>
        {showDetails && (
          <ActionButton
            classname="twilight-button "
            onClick={() => toggleModal(true)}
            text={i18n('Select in detail...')}
          />
        )}
      </div>
    </div>
  );
};

ModelSelect.propTypes = {
  library: PropTypes.string,
  model: PropTypes.object,
  setConfigOptions: PropTypes.func,
  showDetails: PropTypes.bool,
};

ModelSelect.defaultProps = {
  showDetails: true,
};

const mapStateToProps = (state) => {
  return {
    library: getLibrary(state),
    model: getModel(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfigOptions: (options) => dispatch(setConfigOptions(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelect);
