import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { i18n } from 'Utils';
import './ButtonLink.scss';

const ButtonLink = ({text, to, buttonStyle}) => {

  const linkClasses = classNames({
    buttonLink: true,
    [buttonStyle]: true
  });

  return (<Link className={linkClasses} to={to}>{ i18n(`${text}`) }</Link>);
};

export default ButtonLink;
