import React from 'react';
import './AppText.scss';
import { i18n } from 'Utils';
import StaticGPUDetection from '../StaticGPUDetection';

const AppText = ({ droppedFilesLoaded }) => {
  return (
    <div className="apptext my-5">
      <div className="apptext_box col-8">
        <p className="apptext__text my-0">
          {i18n(
            'This application allows you to basecall sequenced DNA within your web browser. Everything happens locally on your machine and no data leaves your device.',
          )}{' '}
        </p>
      </div>
      {droppedFilesLoaded && (
        <div className="apptext_gpu">
          <StaticGPUDetection />
        </div>
      )}
    </div>
  );
};

export default AppText;
