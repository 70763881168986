import React from 'react';

const TotalReads = ({ reads }) => {
  const formattedReads = Intl.NumberFormat('en', { maximumFractionDigits: 1, notation: 'compact' }).format(reads);
  return (
    <div className="reads-wrapper">
      <p className="mb-0">
        <span>{formattedReads}</span>
      </p>
    </div>
  );
};

export default React.memo(TotalReads);
