import React from 'react';

import './Dropdown.scss';

const Dropdown = ({label, selectedValue, onChange, options}) => {

  return (
    <div className="select-wrapper">
      <label htmlFor={`${label}-select`}>{label}</label>
      <select id={`${label}-select`} onChange={onChange} value={selectedValue}>
        {options.map(option => <option key={`${option}-key`} value={option.toLowerCase()}>{option}</option>)}
      </select>
    </div>
   
  );
};

export default Dropdown