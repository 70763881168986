import GlobalConfig from 'Config';
import { UPDATE_CONFIG_OPTIONS } from '../actionTypes';
import { Storage } from 'Services';
import { getWebGPU } from '../deviceInfo';

export const getAppStatus = (state) => state.uiStatusReducer;
export const getLibrary = (state) => state.uiStatusReducer?.library;
export const getBackend = (state) => state.uiStatusReducer?.backend;
export const getInferenceUIStatus = (state) => state.uiStatusReducer?.inferenceStarted;
export const getModel = (state) => state.uiStatusReducer?.model;
export const getFileExtension = (state) => state.uiStatusReducer?.fileExtension;

export const setConfigOptions = (options, type = UPDATE_CONFIG_OPTIONS) => async (dispatch, state) => {
  if (options.backend === 'webgpu' && !getWebGPU(state())) {
    options.backend = 'webgl';
  }

  dispatch({
    type,
    payload: {
      ...options,
    },
  });
};


export const saveDefaultModel = (modelName) => localStorage.setItem('defaultModel', modelName);

export const loadDefaultSettings = () => async (dispatch, getState) => {
  const defaultModel = localStorage.getItem('defaultModel');
  if (defaultModel) {
    const library = getLibrary(getState());
    const libraryModels = GlobalConfig.services.libraries.paths[library];

    const model = libraryModels.find((model) => model.name === defaultModel);
    dispatch(setConfigOptions({ model }));

  }
};

export const clearOfflineModels = async () => Storage['IndexedDB'].deleteDB('tensorflowjs');
