import Global from '../global';
import initialState from '../initialState';

const config = {
  ...Global,
  version: 0.6,
  showLogs: false,
  downloadProfiling: false,
  services: {
    workers: {
      inference: 2,
      decoder: 2,
      file: 1,
    },
    ctc: {
      wasmPath: 'fast_ctc_decode_wasm_bg.wasm',
      profile: {
        beamSize: 5,
        alphabet: ['N', 'A', 'C', 'G', 'T'],
        beamCutThreshold: '0.0',
        collapseRepeats: true,
      },
    },
    libraries: {
      paths: {
        Tensorflow: [
          {
            name: 'dna_r10.4.1_e8.2_400bps_fast@v4.2.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_fast@v4.2.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Fast - 400bps',
            substrate: 'DNA',
            stride: 6,
            features: 320,
          },
          {
            name: 'dna_r10.4.1_e8.2_400bps_hac@v4.2.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_hac@v4.2.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Hac - 400bps',
            substrate: 'DNA',
            stride: 6,
            features: 1280,
          },
          {
            name: 'dna_r10.4.1_e8.2_400bps_fast@v4.1.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_fast@v4.1.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Fast - 400bps',
            substrate: 'DNA',
            stride: 5,
            features: 320,
          },
          {
            name: 'dna_r10.4.1_e8.2_400bps_hac@v4.1.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_hac@v4.1.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Hac - 400bps',
            substrate: 'DNA',
            stride: 5,
            features: 1280,
          },
          {
            name: 'dna_r10.4.1_e8.2_260bps_fast@v4.1.0',
            path: 'models/dna_r10.4.1_e8.2_260bps_fast@v4.1.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Fast - 260bps',
            substrate: 'DNA',
            stride: 5,
            features: 320,
          },
          {
            name: 'dna_r10.4.1_e8.2_260bps_hac@v4.1.0',
            path: 'models/dna_r10.4.1_e8.2_260bps_hac@v4.1.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Hac - 260bps',
            substrate: 'DNA',
            stride: 5,
            features: 1280,
          },
          {
            name: 'dna_r10.4.1_e8.2_400bps_fast@v4.0.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_fast@v4.0.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Fast - 400bps',
            substrate: 'DNA',
            stride: 5,
            features: 320,
          },
          {
            name: 'dna_r10.4.1_e8.2_400bps_hac@v4.0.0',
            path: 'models/dna_r10.4.1_e8.2_400bps_hac@v4.0.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Hac - 400bps',
            substrate: 'DNA',
            stride: 5,
            features: 1280,
          },
          {
            name: 'dna_r10.4.1_e8.2_260bps_fast@v4.0.0',
            path: 'models/dna_r10.4.1_e8.2_260bps_fast@v4.0.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Fast - 260bps',
            substrate: 'DNA',
            stride: 5,
            features: 320,
          },
          {
            name: 'dna_r10.4.1_e8.2_260bps_hac@v4.0.0',
            path: 'models/dna_r10.4.1_e8.2_260bps_hac@v4.0.0/model.json',
            flowcell: ['FLO-FLG114', 'FLO-MIN114', 'FLO-PRO114M'],
            kit: ['Kit 14'],
            algorithm: 'Hac - 260bps',
            substrate: 'DNA',
            stride: 5,
            features: 1280,
          },
          {
            name: 'dna_r9.4.1_e8_fast@v3.4',
            path: 'models/dna_r9.4.1_e8_fast@v3.4/model.json',
            flowcell: ['FLO-FLG001', 'FLO-MIN106', 'FLO-PRO002'],
            kit: ['Kit 9', 'Kit 10', 'Kit 11'],
            algorithm: 'Fast',
            substrate: 'DNA',
            stride: 5,
            features: 320,
          },
          {
            name: 'dna_r9.4.1_e8_hac@v3.3',
            path: 'models/dna_r9.4.1_e8_hac@v3.3/model.json',
            flowcell: ['FLO-FLG001', 'FLO-MIN106', 'FLO-PRO002'],
            kit: ['Kit 9', 'Kit 10', 'Kit 11'],
            algorithm: 'Hac',
            substrate: 'DNA',
            stride: 5,
            features: 1280,
          },
        ],
      },
    },
  },
  mlconfig: {
    overlapAmount: 200,
    tensorSize: 4000,
    batchSize: 240,
    maxSize: 0, // Set max size to 0 to get full read size.
    counter: 0,
    maxReads: 0, // Edit this value to do more reads. Set to 0 to do all reads.
    useNativeProfiler: false, // Set this to true to use inbuilt profilers from onnx/tf. Set to false when doing lots of reads, or will OOM error.
    benchmarkDataAmount: 4000000,
  },
  initialState,
};

export default config;
