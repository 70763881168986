import { UPDATE_CONFIG_OPTIONS } from '../../actions/actionTypes';
import GlobalConfig from 'Config';

export const uiStatusReducer = (state = GlobalConfig.initialState.ui, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONFIG_OPTIONS:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
