import './AnyQuestions.scss';

import React from 'react';
import { i18n } from 'Utils';

const AnyQuestions = () => {
  return (
    <div className="anyquestions my-5">
      <h4 className="anyquestions__title mb-3">{i18n('Any questions?')}</h4>
      <p className="anyquestions__description">
        <u><a href="https://community.nanoporetech.com/support">{i18n('Get in touch with the team')}</a></u>
        {i18n(
          " if you have any feedback or suggestions for this tool. We'd love to hear how you're using it and how we can improve it.",
        )}
      </p>
    </div>
  );
};

export default AnyQuestions;
