import React from 'react';
import './TimelineCard.scss';
import { i18n } from 'Utils';

const TimelineCard = ({image, title, description }) => {
  return (
    <div className="timelinecards">
      <div className="timelinecards__body">
        <img className="timelinecards__image" src={image}/>
        <h5 className="timelinecards__title">{i18n(title)}</h5>
        <p className="timelinecards__description mb-0">{i18n(description)}</p>
      </div>
    </div>
  );
};

export default TimelineCard;
