import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Utils';

const ModelSelectionMain = ({ children, number, title }) => (
  <div className="model-model__section mb-4">
    <h4 className="model-modal__number mb-0">{number}</h4>
    <h5 className="mb-0">{i18n(title)}</h5>
    {children}
  </div>
);

ModelSelectionMain.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
  title: PropTypes.string,
};

export default ModelSelectionMain;
